import axios from "../../axios";

export const KeywordTopTenApi = async (media, startDate, endDate, accountId) => {
    const path = `${media}/${accountId}/keywords/top-ten/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("연결 해지 중 오류 발생:", error);
        throw error;
    }
};
import axios from "../axios";

export const GetMediaApi = async () => {
  try {
    const response = await axios.get("common/mypage/get-medeia/", {});
    return response.data; // 가져온 데이터 반환
  } catch (error) {
    console.error("데이터를 불러오는 중 오류 발생:", error);
    return null;
  }
};

import axios from "../axios";

export const CreateReportApi = async (username, reportNm, mediaData, mediaCode) => {
    try {
        const { data }  = await axios.post("/report/create/", { 
            username: username,
            reportNm: reportNm,
            mediaData: mediaData,
            mediaCode: mediaCode
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        throw error;
    }
};
import React, { useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'

import styles from "./memo.module.scss";

export const Editor = () => {
    const [editorValue, setEditorValue] = useState("");

    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
        ],
    };

    const formats = [
        "size", "color", "background", "bold", "italic",
        "underline", "strike", "blockquote", "list",
        "bullet", "indent", "link", "image",
    ];
    const toolbarOptions = [
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
    ];

    const onChangeEditorValue = (content) => {
        setEditorValue(content);
        console.log(editorValue)
    };

    return(
        <div className={styles.memo}>
            <div className={styles.memoSaveButton}>
                <button>저장</button>
            </div>
            <ReactQuill 
                style={{height: "650px", margin: "4px" }}
                value={editorValue}
                modules={{
                    toolbar: {
                        container: toolbarOptions,
                    }
                }}
                formats={formats}
                onChange={onChangeEditorValue}
            />
        </div>
    );
}
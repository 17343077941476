import React, { useState, useEffect } from 'react';

import styles from '../scss/deviceconversion.module.scss';

import { KeywordDeviceConversionApi } from '../../../../api/report/keyword/KeywordDeviceConversionApi';

import { DevicePieChart } from '../../../utils/chart/DevicePieChart';
import Loading from '../../../utils/loading/Loading';

export const DeviceConversion = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("count");

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordDeviceConversionApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const color1 = () => {
        if (mediaInfoId === 5) {
            return 'rgba(0, 176, 80, 1)';
        } else if (mediaInfoId === 89) {
            return 'rgba(255, 180, 18, 1)';
        } else if (mediaInfoId === 100) {
            return 'rgba(0, 114, 240, 1)';
        }
        return ;
    };

    const color2 = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 0.2)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 0.2)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 0.2)";
        }
        return ;
    };

    const chartData = data.data.map(item => ({
        device: item.device_type,
        value: selectedValue === "amount" ? item.tot_conversion_amount :
               selectedValue === "roas" ? item.roas :
               item.tot_conversion_count
    }));

    return (
        <div className={styles.deviceConversion}>
            <div className={styles.deviceConversionTitle}>
                <p>PC/MO 별 전환</p>
                <div className={styles[`deviceConversionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="count">전환수</option>
                        <option value="amount">매출전환액</option>
                        <option value="roas">광고수익률</option>
                    </select>
                </div>
            </div>
            <div className={styles.deviceConversionContent}>
                <DevicePieChart data={chartData} color1={color1()} color2={color2()} selectedValue={selectedValue} />
            </div>
        </div>
    )
}
import React from "react";
import "./table.scss";

export default function DataTable({ headers, items = [], onRowClick }) {
  if (!headers || !headers.length) {
    throw new Error("<DataTable /> headers is required.");
  }

  const headerKey = headers.map((header) => header.value);
  const sortedData = items.sort(
    (a, b) => new Date(b.date_joined) - new Date(a.date_joined)
  );

  const getStatusStyle = (status) => {
    switch (status) {
      case "승인":
        return "approve";
      case "승인 대기 중":
        return "waiting";
      case "반려 [보기]":
        return "rejected";
      default:
        return {};
    }
  };

  return (
    <div className="approve-main-table">
      <div className="approve-table-thead">
        {headers.map((header, idx) => (
          <div className={`approve-table-th-box ${header.value}`} key={idx}>
            <div
              className="approve-table-th"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {header.text}
            </div>
          </div>
        ))}
      </div>
      <>
        {items.map((item, index) => (
          <div className="approve-table-tbody" key={index}>
            {headerKey.map((key) => (
              <div className={`approve-table-tb-box ${key}`} key={key + index}>
                <div
                  className={`approve-table-tb ${getStatusStyle(item[key])}`}
                  onClick={() => onRowClick(item)}
                >
                  {item[key]}
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    </div>
  );
}

import { useState } from "react";
import Header from "../../shared/header/Header";
import Sidebar from "../../shared/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Login from "../login/Login";
import "./mypage.scss";
import Banner from "./banner/Banner";
import MediaInfo from "./mediaInfo/MediaInfo";
import GaInfo from "./gainfo/GaInfo";
import Help from "./help/Help";
import Footer from "../../shared/footer/Footer";
import { useLocation } from "react-router-dom";
import { path } from "../../store/PathSlice";

const Mypage = () => {
  const user = useSelector((state) => state.user);
  return <>{user.username !== "" ? <LoggedInView /> : <LoggedOutView />}</>;
};

const LoggedInView = () => {
  const [selectTarget, setSelectTarget] = useState("info");
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split("/").pop();
  dispatch(path(url))
  return (
    <div className="mypage-main-box">
      <Header />
      <div className="main-mypage">
        <Sidebar />
        <div className="mypage-container">
          <div className="mypage-contents">
            <div className="banner-container">
              <Banner
                selectTarget={selectTarget}
                setSelectTarget={setSelectTarget}
              />
              <div className="detail-container">
                {selectTarget === "info" && <MediaInfo />}
                {selectTarget === "ga" && <GaInfo />}
                {selectTarget === "help" && <Help />}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export const LoggedOutView = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default Mypage;

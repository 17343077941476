import React, { useState, useEffect } from 'react';

import styles from '../scss/reportscorecard.module.scss';

import { CostScoreCardApi } from '../../../../api/report/cost/CostScoreCardApi'

import { ScoreCard } from '../../../utils/card/ScoreCard';
import Loading from '../../../utils/loading/Loading';

export const ReportScoreCard = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostScoreCardApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = [
        {name: '총 광고비용', value: `${data.data.tot_cost.toLocaleString()}원`, change: data.data.tot_cost_variance, operator: data.data.tot_cost_variance_operator},
        {name: '노출당 비용', value: `${data.data.impression_cost.toLocaleString()}원`, change: data.data.impression_cost_variance, operator: data.data.impression_cost_variance_operator},
        {name: '클릭당 비용', value: `${data.data.cost_click.toLocaleString()}%`, change: data.data.cost_click_variance, operator: data.data.cost_click_variance_operator},
        {name: '전환당 비용', value: `${data.data.conversion_cost.toLocaleString()}원`, change: data.data.conversion_cost_variance, operator: data.data.conversion_cost_variance_operator},
        {name: '매출전환액', value: `${data.data.tot_conversion_amount.toLocaleString()}원`, change: data.data.tot_conversion_amount_variance, operator: data.data.tot_conversion_amount_variance_operator},
    ]

    return (
        <div className={styles.reportScoreCard}>
            <ScoreCard data={transformedData}/>
        </div>
    );
}
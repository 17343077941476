import React from 'react';
import { useSelector } from 'react-redux';

import styles from './reportcost.module.scss';

import { ReportScoreCard } from './scorecard/ReportScoreCard';
import { CostTrend } from './trend/CostTrend';
import { DayImpression } from './impression/DayImpression';
import { DeviceImpression } from './impression/DeviceImpression';
import { DayPerformanceCost } from './performance/DayPerformanceCost';
import { CampaignCost } from './campaign/CampaignCost';
import { DetailedAnalysis } from './campaign/DetailedAnalysis';

import { selectStartDate, selectEndDate } from '../../../store/dateSlice';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportCost = ({mediaInfoId, adAccountsId}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));

    return (
        <div className={styles.reportCost}>
            <ReportScoreCard mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <CostTrend mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <div className={styles.costImpressions}>
                <DayImpression mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <DeviceImpression mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            </div>
                <DayPerformanceCost mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <CampaignCost mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <DetailedAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
        </div>
    );
};


import React, { useState, useEffect } from 'react';

import styles from '../scss/topkeyword.module.scss';

import Loading from '../../../utils/loading/Loading';

import { KeywordTopTenApi } from '../../../../api/report/keyword/KeywordTopTenApi';

export const TopKeyword = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordTopTenApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const testData = [
        {"키워드": "키워드A", "PC/MO": "PC", "전환수": 10, "매출전환액": 9032423, "광고 수익률": 10.32},
        {"키워드": "키워드B", "PC/MO": "PC", "전환수": 9, "매출전환액": 8032423, "광고 수익률": 10.32},
        {"키워드": "키워드C", "PC/MO": "PC", "전환수": 8, "매출전환액": 7032423, "광고 수익률": 10.32},
        {"키워드": "키워드D", "PC/MO": "MO", "전환수": 7, "매출전환액": 6032423, "광고 수익률": 10.32},
        {"키워드": "키워드E", "PC/MO": "MO", "전환수": 6, "매출전환액": 5032423, "광고 수익률": 10.32},
        {"키워드": "키워드F", "PC/MO": "PC", "전환수": 5, "매출전환액": 4032423, "광고 수익률": 10.32},
        {"키워드": "키워드G", "PC/MO": "PC", "전환수": 4, "매출전환액": 3032423, "광고 수익률": 10.32},
        {"키워드": "키워드H", "PC/MO": "MO", "전환수": 3, "매출전환액": 2032423, "광고 수익률": 10.32},
        {"키워드": "키워드I", "PC/MO": "PC", "전환수": 2, "매출전환액": 1032423, "광고 수익률": 10.32},
        {"키워드": "키워드J", "PC/MO": "MO", "전환수": 1, "매출전환액": 932423, "광고 수익률": 10.32},
    ]

    const transformedData = data.data.map(item => ({
        "키워드": item.keyword_name,
        "PC/MO": item.device_type,
        "전환수": item.tot_conversion_count.toLocaleString(),
        "전환매출액": `₩${item.tot_conversion_amount.toLocaleString()}`,
        "광고수익률": item.roas_variance_operator === 'up' 
            ? `${item.roas.toFixed(0)}%(▲ ${item.roas_variance.toFixed(0)}%)` 
            : item.roas_variance_operator === 'down' 
            ? `${item.roas.toFixed(0)}%(▼ ${item.roas_variance.toFixed(0)}%)` 
            : `${item.roas.toFixed(0)}%(${item.roas_variance.toFixed(0)}%)`,
        "roas_variance_operator": item.roas_variance_operator
    }));

    const maxConversion = Math.max(...transformedData.map(item => item.전환수));
    const maxRevenue = Math.max(...transformedData.map(item => item.전환매출액));

    return (
        <div className={styles.topKeyword}>
            <div className={styles.topKeywordTitle}>매출 상위 10개 키워드</div>
            <div className={styles.topKeywordTable}>
                <div className={styles.topKeywordTableHeader}>
                    {Object.keys(transformedData[0]).filter(key => key !== 'roas_variance_operator').map((key, index) => ( // roas_variance_operator 제외
                        <div key={index} className={styles.headerCell}>{key}</div>
                    ))}
                </div>
                <div className={styles.topKeywordTableBody}>
                    {transformedData.map((item, rowIndex) => (
                        <div key={rowIndex} className={styles.tableRow}>
                            {Object.entries(item).filter(([key]) => key !== 'roas_variance_operator').map(([key, value], cellIndex) => ( // roas_variance_operator 제외
                                <div key={cellIndex} className={styles.tableCell}>
                                    {(key === '전환수' || key === '전환매출액') ? (
                                        <div className={styles.cellContent}>
                                            <span>{value}</span>
                                            <div 
                                                className={styles.bar}
                                                style={{
                                                    width: `${(value / (key === '전환수' ? maxConversion : maxRevenue)) * 100}%`,
                                                    backgroundColor: key === '전환수' ? '#D1EFDE' : '#D6EBF9'
                                                }}
                                            />
                                        </div>
                                    ) : key === '광고수익률' ? (
                                        <span style={{ color: item.roas_variance_operator === 'up' ? 'blue' : item.roas_variance_operator === 'down' ? 'red' : 'gray' }}>
                                            {value}
                                        </span>
                                    ) : (
                                        value
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

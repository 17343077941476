import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Adforest } from "../../../../assets/images/home/home-adforest.svg"

export const Predict = () => {
    return (
        <div className={styles.predictContent}>
            <div className={styles.predictBox}>
                <div className={styles.predictImg}>
                    <Adforest />
                </div>
                <p className={styles.predictTxt1}>광고비 예측 분석 보고서</p>
                <p className={styles.predictTxt2}>광고비를 입력하고 매출을 예측해보세요!</p>
                <p className={styles.predictTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from 'react';

import styles from "../scss/keywordfunnel.module.scss"
import { ReactComponent as FunnelIcon } from "../../../../assets/images/report/report-funnel-icon.svg";

import { KeywordFunnelApi } from '../../../../api/report/keyword/KeywordFunnelApi';
import Loading from '../../../utils/loading/Loading';

const FunnelStep = ({ step, text, value, mediaInfoId }) => (
    <div className={styles[`keywordFunnelChart${step}${mediaInfoId}`]}>
        <p className={styles[`chart${step}${mediaInfoId}Text1`]}>{text}</p>
        <p className={styles[`chart${step}${mediaInfoId}Text2`]}>{value}</p>
    </div>
);

const DataItem = ({ name, value, percent, operator, className }) => {
    const percentValue = operator;
    const percentClass = percentValue === "up" ? 'funnelPositive' : percentValue === "down" ? 'funnelNegative' : 'funnelZeroChange';
    const formattedPercent = percentValue === "up" ? `+${percent}%` : percentValue === "down" ? `-${percent}%` : `-`;

    return (
        <div className={styles[className]}>
            <p className={styles.funnelDataName}>{name}</p>
            <p className={styles.funnelDataValue}>{value}</p>
            <pre className={styles[percentClass]}>{formattedPercent}</pre>
        </div>
    );
};

const formatNumber = (num) => {
    return num.toLocaleString();
};

export const KeywordFunnel = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordFunnelApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const funnelSteps = [
        { step: 'First', text: '1단계', value: `${formatNumber(data.data.tot_impression)} 회 노출`, mediaInfoId },
        { step: 'Second', text: '2단계', value: `${formatNumber(data.data.tot_click)} 회 클릭`, mediaInfoId },
        { step: 'Third', text: '3단계', value: `${formatNumber(data.data.tot_conversion_count)} 번 전환`, mediaInfoId },
    ];

    const funnelData = [
        {
            className: `funnelImpressionData${mediaInfoId}`,
            items: [
                { name: '노출수', value: `${formatNumber(data.data.tot_impression)}회`, percent: `${data.data.tot_impression_variance.toFixed(2)}`, operator: `${data.data.tot_impression_variance_operator}` },
                { name: '총 광고비용', value: `${formatNumber(data.data.tot_cost)}원`, percent: `${data.data.tot_cost_variance.toFixed(2)}`, operator: `${data.data.tot_cost_variance_operator}` },
                { name: '노출당 비용', value: `${formatNumber(data.data.impression_cost)}원`, percent: `${data.data.impression_cost_variance.toFixed(2)}`, operator: `${data.data.impression_cost_variance_operator}` },
            ],
        },
        {
            className: `funnelClickData${mediaInfoId}`,
            items: [
                { name: '클릭수', value: `${formatNumber(data.data.tot_click)}회`, percent: `${data.data.tot_click_variance.toFixed(2)}`, operator: `${data.data.tot_click_variance_operator}` },
                { name: '클릭률', value: `${data.data.click_rate}%`, percent: `${data.data.click_rate_variance.toFixed(2)}`, operator: `${data.data.click_rate_variance_operator}` },
                { name: '클릭당 비용', value: `${formatNumber(data.data.cost_click)}원`, percent: `${data.data.cost_click_variance.toFixed(2)}`, operator: `${data.data.cost_click_variance_operator}` },
            ],
        },
        {
            className: `funnelConversionData${mediaInfoId}`,
            items: [
                { name: '전환수', value: `${formatNumber(data.data.tot_conversion_count)}회`, percent: `${data.data.tot_conversion_count_variance.toFixed(2)}`, operator: `${data.data.tot_conversion_count_variance_operator}` },
                { name: '전환율', value: `${data.data.conversion_rate}%`, percent: `${data.data.conversion_rate_variance.toFixed(2)}`, operator: `${data.data.conversion_rate_variance_operator}` },
                { name: '전환당 비용', value: `${formatNumber(data.data.conversion_cost)}원`, percent: `${data.data.conversion_cost_variance.toFixed(2)}`, operator: `${data.data.conversion_cost_variance_operator}` },
            ],
        },
    ];

    return (
        <div className={styles.keywordFunnel}>
            <div className={styles.keywordFunnelTitle}>
                <p>단계별 진행 상황</p>
                <FunnelIcon />
            </div>
            <div className={styles.keywordFunnelContent}>
                <div className={styles.keywordFunnelChart}>
                    {funnelSteps.map((step, index) => (
                        <FunnelStep key={index} {...step} />
                    ))}
                </div>
                <div className={styles.keywordFunnelDataBox}>
                    {funnelData.map((section, index) => (
                        <div key={index} className={styles.keywordFunnelDataItem}>
                            {section.items.map((item, itemIndex) => (
                                <DataItem key={itemIndex} {...item} className={section.className} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

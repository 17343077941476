import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Adforest } from "../../../../assets/images/home/home-adforest.svg"

export const Segment = () => {
    return (
        <div className={styles.rfmContent}>
            <div className={styles.predictBox}>
                <div className={styles.predictImg}>
                    <Adforest />
                </div>
                <p className={styles.predictTxt1}>세그먼트 분석 보고서</p>
                <p className={styles.predictTxt2}>다양한 상황으로 분류하여 분석해보세요!</p>
                <p className={styles.predictTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}
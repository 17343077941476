import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import './table.scss'
import CustomPagination from "../pagination/Pagination";
import BarChart from "../chart/BarChart";
import {ReactComponent as SortMark} from '../../../assets/images/share/sort.svg'
import {ReactComponent as UpSortMark} from '../../../assets/images/share/upsort.svg'
import {ReactComponent as DownSortMark} from '../../../assets/images/share/downsort.svg'



export default function DataTable(
    { 
        headers,
        items = [],
        totalItems=[],
        sort,
        selectViews,
        maxValues
    }) {
    if (!headers || !headers.length) {
        throw new Error('<DataTable /> headers us required.')
    }

    const [sortedItems, setSortedItems] = useState([...totalItems]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
    const [page, setPage] = useState(1);
    const views = selectViews ? selectViews : 10; 
    const path = useSelector((state) => state.path.path)

    useEffect(() => {
        if (path == 'rfm') {
            setSortedItems(totalItems);
        }
    }, [totalItems]);

    const headerKey = headers.map((header) => header.value);

    // 정렬 가능한 단위 리스트
    const units = ['초', '%', '₩'];

    // 숫자 추출 함수 (숫자와 천 단위 구분자 제거)
    const extractNumber = (str) => {
        const numStr = str.replace(/[^\d.-]/g, ''); // 숫자와 소수점, 마이너스 기호만 남김
        return numStr ? parseFloat(numStr) : 0; // 실수로 변환
    };

    // 특정 단위가 있는지 확인하는 함수
    const hasUnit = (str) => {
        return units.some(unit => str.includes(unit));
    };

    // 숫자 또는 특정 단위가 있는 문자열인지 확인하는 함수
    const isNumberString = (str) => {
        return /^[\d,.-]+$/.test(str) || hasUnit(str); // 숫자 또는 특정 단위 포함 여부 확인
    };

    // 정렬 함수
    const sortItems = (key) => {
        let direction = 'descending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        } else if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'none';
        }

        const sorted = [...totalItems];
        if (direction !== 'none') {
            sorted.sort((a, b) => {
                if (typeof a[key] === 'string' && isNumberString(a[key]) && isNumberString(b[key])) {
                    const numA = extractNumber(a[key]);
                    const numB = extractNumber(b[key]);
                    return direction === 'ascending' ? numA - numB : numB - numA;
                } else if (typeof a[key] === 'string') {
                    return direction === 'ascending' 
                        ? a[key].localeCompare(b[key]) 
                        : b[key].localeCompare(a[key]);
                } else {
                    return direction === 'ascending'
                        ? a[key] - b[key]
                        : b[key] - a[key];
                }
            });
        }

        setSortedItems(direction === 'none' ? [...totalItems] : sorted);
        setSortConfig({ key, direction });
    };

    const sortedItem = sortedItems.slice((page - 1) * views, page * views)

    const colors = {
        view_product: 'rgba(255, 99, 132, 0.6)',
        add_cart_product: 'rgba(54, 162, 235, 0.6)',
        buy_product: 'rgba(255, 206, 86, 0.6)',
        revenue: 'rgba(75, 192, 192, 0.6)',
        purchase_rate: 'rgba(153, 102, 255, 0.6)',
    };

    return (
        <div className="main-table">
            <div className="table-thead">
                {sort? (
                    headers.map((header) => 
                    <div className="table-th-box" key={header.value}>
                        <button className="table-th" onClick={() => sortItems(header.value)}>
                            {header.text} 
                            <span>
                                {sortConfig.key === header.value ? (sortConfig.direction === 'ascending' ? <UpSortMark/> : sortConfig.direction === 'descending' ? <DownSortMark/> : <SortMark />) : <SortMark />}
                            </span> 
                        </button>
                    </div>
                    )
                ):(
                    headers.map((header) => 
                    <div className="table-th-box">
                        <button className="table-th" key={header.text}>
                            {header.text}
                        </button>
                    </div>
                    )
                )}
            </div>
            {sort ? (
                <>
                    {sortedItem.map((item, index) => (
                        <div className="table-tbody" key={index}>
                            {headerKey.map((key) => (
                                <div className="table-tb-box" key={key + index}>
                                    <div className="table-tb">
                                        {item[key]}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <CustomPagination 
                        total={sortedItems.length} 
                        page={page} 
                        setPage={setPage} 
                        views={views} 
                    />
                </>
            ) : (
                <>
                    {items.map((item, index) => (
                        <div className="table-tbody" key={index}>
                            {headerKey.map((key) => (
                                <div className="table-tb-box" key={key + index}>
                                    <div className="table-tb">
                                        <div className="item-bar">
                                        {item[key]}
                                        {maxValues && maxValues[key] != null && (
                                            <BarChart
                                                value={item[key]}
                                                maxValue={maxValues[key]}
                                                color={colors[key]}
                                            />
                                        )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}

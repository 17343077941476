import axios from "../../axios";

export const CostCampaignGaugeChartApi = async ( media, startDate, endDate, accountId ) => {
    const path = `${media}/${accountId}/costs/campaigns-donut/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("캠페인별 비용 게이지차트 오류 발생:", error);
        throw error;
    }
};

export const CostCampaignLineChartApi = async ( media, startDate, endDate, accountId, campaignId, period_type ) => {
    const path = `${media}/${accountId}/costs/campaign-chart/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                campaign_ids: Array.isArray(campaignId) ? campaignId.join(',') : campaignId,
                period_type: period_type
            }
        });
        return data;
    } catch (error) {
        console.error("캠페인별 비용 라인차트 오류 발생:", error);
        throw error;
    }
};
import React from "react";

import styles from "./report.module.scss"
import { ReactComponent as Naver } from "../../../../assets/images/home/home-report-naver.svg"
import { ReactComponent as Kakao } from "../../../../assets/images/home/home-report-kakao.svg"
import { ReactComponent as Ga4 } from "../../../../assets/images/home/home-report-ga4.svg"
import { ReactComponent as Google } from "../../../../assets/images/home/home-report-google.svg"
import { ReactComponent as Facebook } from "../../../../assets/images/home/home-report-facebook.svg"

export const Report = () => {
    return (
        <div className={styles.report}>
            <div className={styles.currentBox}>
                <p className={styles.currentTxt}>보고서 현황</p>
                <div className={styles.totalReport}>
                    <p className={styles.reportTxt1}>전체 보고서 개수</p>
                    <p className={styles.reportTxt2}>11</p>
                </div>
                <div className={styles.createReport}>
                    <p className={styles.createTxt1}>생성 가능한 보고서 개수</p>
                    <p className={styles.createTxt2}>5</p>
                </div>
            </div>
            <div className={styles.recommendBox}>
                <p className={styles.recommendTxt}>추천 보고서</p>
                <div className={styles.recommendContainer}>
                    <div className={styles.recommendContent}>
                        <div className={styles.recommendImg}><Naver /></div>
                        <p className={styles.recommendTxt1}>네이버 검색 광고 보고서</p>
                        <p className={styles.recommendTxt2}>--&gt;</p>
                    </div>
                    <div className={styles.recommendContent}>
                        <div className={styles.recommendImg}><Kakao /></div>
                        <p className={styles.recommendTxt1}>카카오 키워드 보고서</p>
                        <p className={styles.recommendTxt2}>--&gt;</p>
                    </div>
                    <div className={styles.recommendContent}>
                        <div className={styles.recommendImg}>
                            <Ga4 />
                            <Naver />
                            <Facebook />
                            <Kakao />
                            <Google />
                        </div>
                        <p className={styles.recommendTxt3}>매체 통합 보고서</p>
                        <p className={styles.recommendTxt2}>--&gt;</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from "react";

const ModalContent = ({ModalTitle}) => {
    if (ModalTitle === "이용약관 동의") {
        return (
            <p>
                제1장 총칙<br/>
                 제1조(목적) 이 약관은 앱 마켓 이용계약, 디지털콘텐츠 및 온라인 서비스 이용계약의 중개에 있어서 앱 마켓사업자와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.<br/>
                 제2조(정의) 이 약관에서 사용하는 용어의 뜻은 다음과 같습니다. <br/>
                1. "앱 마켓"이라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠 또는 온라인 서비스(이하 "디지털콘텐츠등"이라 합니다)의 거래를 중개하는 앱 마켓사업자의 가상 영업장을 말합니다. <br/>
                2. "사이버몰"이라 함은 디지털콘텐츠등을 거래할 수 있는 디지털콘텐츠등제공사업자의 가상 영업장을 말합니다. <br/>
                3. "앱 마켓사업자"라 함은 앱 마켓을 운영하는 것을 업으로 하는 자를 말합니다. <br/>
                4. "디지털콘텐츠등제공사업자"라 함은 디지털콘텐츠등에 관한 거래를 업으로 하는 자를 말합니다. <br/>
                5. "이용자"라 함은 앱 마켓사업자가 제공하는 서비스를 이용하는 자를 말합니다. <br/>
                6. "회원"이라 함은 앱 마켓 이용계약을 체결하고, 회원 아이디(ID)를 부여받은 이용자를 말합니다. <br/>
                7. "비회원"이라 함은 회원이 아닌 이용자를 말합니다. <br/>
                8. "소비자"라 함은 다음 각 목의 어느 하나에 해당하는 자를 말합니다. <br/>
                가. 앱 마켓사업자 또는 디지털콘텐츠등제공사업자(이하 "앱 마켓사업자등"이라고 합니다)가 제공하는 디지털콘텐츠등을 소비생활을 위하여 이용하는 자 <br/>
                나. 가목 외의 자로서 사실상 가목의 자와 같은 지위 및 거래조건으로 디지털콘텐츠등을 거래하는 자 <br/>
                다. 디지털콘텐츠등을 최종적으로 사용하거나 이용하는 사업자. 다만, 디지털콘텐츠등을 원재료(중간재를 포함합니다) 및 자본재로 사용하는 사업자는 제외합니다. <br/>
                라. 디지털콘텐츠등을 농업(축산업을 포함합니다) 또는 어업 활동을 위하여 구입한 자. 다만, 「원양산업발전법」 제6조제1항에 따라 해양수산부장관의 허가를 받은 원양어업자는 제외합니다. <br/>
                9. "이용사업자"라 함은 이용자 중 소비자가 아닌 자를 말합니다. <br/>
                10. "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을 말합니다. <br/>
                11. "중개 서비스"라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠등에 관한 거래가 체결될 수 있도록 조력하는 앱 마켓사업자의 알선행위를 말합니다. <br/>
                12. "온라인 서비스"라 함은 앱 마켓사업자의 중개서비스를 제외하고, 앱 마켓사업자등이 앱 마켓 또는 사이버몰(이하 "앱 마켓등"이라고 합니다)에서 이용자에게 제공하는 유상 또는 무상의 서비스를 말합니다. <br/>
                13. "아이디(ID)"라 함은 앱 마켓에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 말합니다. <br/>
                14. "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위해 아이디와 일치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다. <br/>
                15. "포인트"라 함은 이용자가 디지털콘텐츠등의 대금으로 지급할 수 있는 전자결제수단으로써 앱 마켓사업자가 발행한 것을 말합니다. <br/>
                16. "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로 온라인 서비스 또는 중개 서비스(이하 "온라인 서비스등"이라 합니다)를 제공하는 계약으로 중도에 해지할 경우 대금 환급의 제한 또
            </p>
        );
    };
    if (ModalTitle === "개인정보 수집 및 이용 동의") {
        return (
            <p>
                개인정보
                개인정보 수집 및 이용 동의
                제1장 총칙
                 제1조(목적) 이 약관은 앱 마켓 이용계약, 디지털콘텐츠 및 온라인 서비스 이용계약의 중개에 있어서 앱 마켓사업자와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.
                 제2조(정의) 이 약관에서 사용하는 용어의 뜻은 다음과 같습니다.  
                1. "앱 마켓"이라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠 또는 온라인 서비스(이하 "디지털콘텐츠등"이라 합니다)의 거래를 중개하는 앱 마켓사업자의 가상 영업장을 말합니다. 
                2. "사이버몰"이라 함은 디지털콘텐츠등을 거래할 수 있는 디지털콘텐츠등제공사업자의 가상 영업장을 말합니다. 
                3. "앱 마켓사업자"라 함은 앱 마켓을 운영하는 것을 업으로 하는 자를 말합니다. 
                4. "디지털콘텐츠등제공사업자"라 함은 디지털콘텐츠등에 관한 거래를 업으로 하는 자를 말합니다. 
                5. "이용자"라 함은 앱 마켓사업자가 제공하는 서비스를 이용하는 자를 말합니다. 
                6. "회원"이라 함은 앱 마켓 이용계약을 체결하고, 회원 아이디(ID)를 부여받은 이용자를 말합니다. 
                7. "비회원"이라 함은 회원이 아닌 이용자를 말합니다. 
                8. "소비자"라 함은 다음 각 목의 어느 하나에 해당하는 자를 말합니다. 
                가. 앱 마켓사업자 또는 디지털콘텐츠등제공사업자(이하 "앱 마켓사업자등"이라고 합니다)가 제공하는 디지털콘텐츠등을 소비생활을 위하여 이용하는 자 
                나. 가목 외의 자로서 사실상 가목의 자와 같은 지위 및 거래조건으로 디지털콘텐츠등을 거래하는 자 
                다. 디지털콘텐츠등을 최종적으로 사용하거나 이용하는 사업자. 다만, 디지털콘텐츠등을 원재료(중간재를 포함합니다) 및 자본재로 사용하는 사업자는 제외합니다. 
                라. 디지털콘텐츠등을 농업(축산업을 포함합니다) 또는 어업 활동을 위하여 구입한 자. 다만, 「원양산업발전법」 제6조제1항에 따라 해양수산부장관의 허가를 받은 원양어업자는 제외합니다. 
                9. "이용사업자"라 함은 이용자 중 소비자가 아닌 자를 말합니다. 
                10. "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을 말합니다. 
                11. "중개 서비스"라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠등에 관한 거래가 체결될 수 있도록 조력하는 앱 마켓사업자의 알선행위를 말합니다. 
                12. "온라인 서비스"라 함은 앱 마켓사업자의 중개서비스를 제외하고, 앱 마켓사업자등이 앱 마켓 또는 사이버몰(이하 "앱 마켓등"이라고 합니다)에서 이용자에게 제공하는 유상 또는 무상의 서비스를 말합니다. 
                13. "아이디(ID)"라 함은 앱 마켓에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 말합니다. 
                14. "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위해 아이디와 일치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다. 
                15. "포인트"라 함은 이용자가 디지털콘텐츠등의 대금으로 지급할 수 있는 전자결제수단으로써 앱 마켓사업자가 발행한 것을 말합니다. 
                16. "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로 온라인 서비스 또는 중개 서비스(이하 "온라인 서비스등"이라 합니다)를 제공하는 계약으로 중도에 해지할 경우 대금 환급의 제한 또
            </p>
        );
    };
    if (ModalTitle === "광고성 정보 수신 동의") {
        return (
            <p>
                광고성 정보
                제1장 총칙
                 제1조(목적) 이 약관은 앱 마켓 이용계약, 디지털콘텐츠 및 온라인 서비스 이용계약의 중개에 있어서 앱 마켓사업자와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.
                 제2조(정의) 이 약관에서 사용하는 용어의 뜻은 다음과 같습니다.  
                1. "앱 마켓"이라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠 또는 온라인 서비스(이하 "디지털콘텐츠등"이라 합니다)의 거래를 중개하는 앱 마켓사업자의 가상 영업장을 말합니다. 
                2. "사이버몰"이라 함은 디지털콘텐츠등을 거래할 수 있는 디지털콘텐츠등제공사업자의 가상 영업장을 말합니다. 
                3. "앱 마켓사업자"라 함은 앱 마켓을 운영하는 것을 업으로 하는 자를 말합니다. 
                4. "디지털콘텐츠등제공사업자"라 함은 디지털콘텐츠등에 관한 거래를 업으로 하는 자를 말합니다. 
                5. "이용자"라 함은 앱 마켓사업자가 제공하는 서비스를 이용하는 자를 말합니다. 
                6. "회원"이라 함은 앱 마켓 이용계약을 체결하고, 회원 아이디(ID)를 부여받은 이용자를 말합니다. 
                7. "비회원"이라 함은 회원이 아닌 이용자를 말합니다. 
                8. "소비자"라 함은 다음 각 목의 어느 하나에 해당하는 자를 말합니다. 
                가. 앱 마켓사업자 또는 디지털콘텐츠등제공사업자(이하 "앱 마켓사업자등"이라고 합니다)가 제공하는 디지털콘텐츠등을 소비생활을 위하여 이용하는 자 
                나. 가목 외의 자로서 사실상 가목의 자와 같은 지위 및 거래조건으로 디지털콘텐츠등을 거래하는 자 
                다. 디지털콘텐츠등을 최종적으로 사용하거나 이용하는 사업자. 다만, 디지털콘텐츠등을 원재료(중간재를 포함합니다) 및 자본재로 사용하는 사업자는 제외합니다. 
                라. 디지털콘텐츠등을 농업(축산업을 포함합니다) 또는 어업 활동을 위하여 구입한 자. 다만, 「원양산업발전법」 제6조제1항에 따라 해양수산부장관의 허가를 받은 원양어업자는 제외합니다. 
                9. "이용사업자"라 함은 이용자 중 소비자가 아닌 자를 말합니다. 
                10. "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을 말합니다. 
                11. "중개 서비스"라 함은 디지털콘텐츠등제공사업자와 이용자간 디지털콘텐츠등에 관한 거래가 체결될 수 있도록 조력하는 앱 마켓사업자의 알선행위를 말합니다. 
                12. "온라인 서비스"라 함은 앱 마켓사업자의 중개서비스를 제외하고, 앱 마켓사업자등이 앱 마켓 또는 사이버몰(이하 "앱 마켓등"이라고 합니다)에서 이용자에게 제공하는 유상 또는 무상의 서비스를 말합니다. 
                13. "아이디(ID)"라 함은 앱 마켓에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 말합니다. 
                14. "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위해 아이디와 일치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다. 
                15. "포인트"라 함은 이용자가 디지털콘텐츠등의 대금으로 지급할 수 있는 전자결제수단으로써 앱 마켓사업자가 발행한 것을 말합니다. 
                16. "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로 온라인 서비스 또는 중개 서비스(이하 "온라인 서비스등"이라 합니다)를 제공하는 계약으로 중도에 해지할 경우 대금 환급의 제한 또
            </p>
        );
    };
}

export default ModalContent;
import axios from "../axios"

export const KakaoMomentLinkApi = async (code) => {
    try {
        const response = await axios.post('kakao/moment-medialink/',
            { code },
            { headers: { 'Content-Type': 'application/json' } }
        );
        console.log(response);
        if (response.status==200 && response.data) {
            return response.data; // 가져온 데이터 반환
        }
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}

export const KakaoMomentTargetApi = async (media_link_id) => {
    try {
        const response = await axios.post('kakao/moment-target/',
            { media_link_id },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}
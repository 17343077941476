import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = ({ value, maxValue, color }) => {
    const units = ['초', '%', '₩'];

    // 숫자 추출 함수 (숫자와 천 단위 구분자 제거)
    const extractNumber = (str) => {
        const numStr = str.replace(/[^\d.-]/g, ''); // 숫자와 소수점, 마이너스 기호만 남김
        return numStr ? parseFloat(numStr) : 0; // 실수로 변환
    };

    // 특정 단위가 있는지 확인하는 함수
    const hasUnit = (str) => {
        return units.some(unit => str.includes(unit));
    };

    // 숫자 또는 특정 단위가 있는 문자열인지 확인하는 함수
    const isNumberString = (str) => {
        return /^[\d,.-]+$/.test(str) || hasUnit(str); // 숫자 또는 특정 단위 포함 여부 확인
    };

    // 변환 함수
    const transformValue = (value) => {
        if (Array.isArray(value)) {
            return value.map(item => {
                if (typeof item === 'string' && isNumberString(item)) {
                    return extractNumber(item);
                }
                return item; // 예상치 못한 타입일 경우 원래 값을 반환
            });
        } else {
            if (typeof value === 'string' && isNumberString(value)) {
                return extractNumber(value);
            }
            return value; // 예상치 못한 타입일 경우 원래 값을 반환
        }
    };

    const transformedValues = transformValue(value);

    const data = {
        labels: [''],
        datasets: [
        {
            label: '',
            data: [transformedValues],
            backgroundColor: color
        },
        ],
    };

    const options = {
        indexAxis: 'y',
        responsive: false,
        maintainAspectRatio: false,
        scales: {
        x: {
            suggestedMin: 0,
            suggestedMax: maxValue,
            display: false,
        },
        y: {
            display: false,
        },
        },
        plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            display: false,
        },
        datalabels: {
            display: false,
        },
        },
    };

    if (
        (Array.isArray(transformedValues) && transformedValues.every(val => val === 0)) ||
        maxValue === 0
    ) {
        return <></>;
    }

    return (
        <div style={{ width: '50px', height: '20px', marginLeft: '2px' }}>
            <Bar data={data} options={options} style={{ width: '100%', height: '100%' }}/>
        </div>
    );
};

export default BarChart;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { GoogleApi } from "../../../../../api/socialLogin/GoogleApi";
import { setCode } from "../../../../../store/SocialSlice";

const GoogleCallback = () => {
    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        const qsError = searchParams.get("error");
        console.log(code)
        console.log(qsError);
        
        dispatch(setCode(code))

        const getToken = async (code) => {
            try {
                const response = await GoogleApi(code);

                console.log(response);
                setData(response);
                localStorage.setItem("googleads", JSON.stringify(response));
                navigate("/datalink/google/modal");
            } catch (err) {
                console.log(err);
                navigate("/datalink")
            }
        };
        const MovePage = () =>{
            console.log(qsError);
            navigate("/datalink")
            
            
        }
        if (code) {
            getToken(code);
        }
        if (qsError) {
            console.log(qsError);
            MovePage(qsError)
        }
    }, [location.search, navigate]);

    return (
        <div>
            {data ? <div>Data Loaded</div> : <div>Loading...</div>}
        </div>
    );
};

export default GoogleCallback;

import React from "react";
import styles from "./summary.module.scss";

export const Summary = ({mediaInfoId}) => {
    const testData = [
        {"ment": "2024-09-01 ~ 2024-10-01 기간동안, 총 23,000,000원의 광고비용이 소진되었습니다." },
        {"ment": "방문자 128명 중 1명이 전환을 발생시켰고, 1명 당 평균적으로 7,608원의 매출을 발생시켰어요!"},
        {"ment": "100명의 방문자 중 약 0.78명 만 전환으로 이어지고 있어요! 현재 1명의 전환을 발생시키는데 약 63,500원의 광고비가 필요한 상황이에요!"},
        {"ment": "전환과 매출이 가장 높은 요일은 토요일이고 가장 낮은 요일은 일요일이에요! 일요일의 광고비중을 줄이고 토요일 비중을 늘려보시는건 어떨까요?"},
        {"ment": "총 23,000,000원의 광고비용이 소진되었습니다.총 23,000,000원의 광고비용이 소진되었습니다."},
        {"ment": "총 23,000,000원의 광고비용이 소진되었습니다.총 23,000,000원의 광고비용이 소진되었습니다."},
        {"ment": "총 23,000,000원의 광고비용이 소진되었습니다.총 23,000,000원의 광고비용이 소진되었습니다."}
    ]
    return (
        <div className={styles.summary}>
            <div className={styles.summaryTitle}>
                <p className={styles.summaryTitleText}>보고서 핵심 요약</p>
                <p className={styles.summaryTitleDate}>(2024-09-01 ~ 2024-10-01)</p>
            </div>
            <div className={styles[`summaryContent${mediaInfoId}`]}>
                {testData.map((data, index) => (
                    <p key={index} className={styles.summaryContentText}>{data.ment}</p>
                ))}
            </div>
        </div>
    );
};
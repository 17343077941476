import React, { useState, useMemo } from 'react';
import styles from './targetresult.module.scss';

export const TargetResult = ({mediaInfoId}) => {
    const [data, setData] = useState({ conversions:620, revenue:6000000 });
    const [targetData, setTargetData] = useState({ conversions: 0, revenue: 0 });
    
    const [showTargetInput, setShowTargetInput] = useState(false);

    const handleInputChange = (field, value) => {
        const numValue = parseInt(value.replace(/,/g, ''), 10) || 0;
        setTargetData(prev => ({ ...prev, [field]: numValue }));
    };

    const handleSubmit = () => {
        // 여기에 targetData를 저장하거나 처리하는 로직을 추가
        setShowTargetInput(false);
        console.log(targetData);
    };

    const percentages = useMemo(() => {
        const conversionPercent = targetData.conversions > 0
            ? Math.min(100, (data.conversions / targetData.conversions) * 100)
            : 0;
        const revenuePercent = targetData.revenue > 0
            ? Math.min(100, (data.revenue / targetData.revenue) * 100)
            : 0;
        return {
            conversions: conversionPercent.toFixed(1),
            revenue: revenuePercent.toFixed(1)
        };
    }, [data, targetData]);

    return (
        <>
            {!showTargetInput ? (
                <>
                {targetData.conversions===0 || targetData.revenue===0 ? (
                    <div className={styles.targetResult}>
                        <div className={styles[`targetResultTitle${mediaInfoId}`]}>목표 성과</div>
                        <div className={styles[`targetResultContent${mediaInfoId}`]}>
                            <p>목표 성과를 설정해주세요.</p>
                            <button onClick={() => setShowTargetInput(true)}>목표 성과 설정하기</button>
                        </div>
                    </div>
                ):(
                    <div className={styles.targetResult}>
                        <div className={styles[`targetResultTitle${mediaInfoId}`]}>
                            <p>목표 성과</p>
                            <button onClick={() => setShowTargetInput(true)}>목표 변경하기</button>
                        </div>
                        <div className={styles.targetResultGaugeContent}>
                            <div className={styles.targetResultGaugeItem}>
                                <p className={styles.targetResultGaugeItemTitle}>전환수</p>
                                <div>
                                    <div className={styles.targetGaugeBox}>
                                        <div className={styles[`targetGauge${mediaInfoId}`]}></div>
                                        <div className={styles[`targetGaugeBar${mediaInfoId}`]} style={{width: `${percentages.conversions}%`}}></div>
                                    </div>
                                    <div className={styles.targetGaugeValue}>
                                        <pre className={styles.targetGaugeValueZero}>0</pre>
                                        <pre className={styles[`targetGaugeValueText${mediaInfoId}`]} style={{width: `${percentages.conversions}%`}}>{data.conversions.toLocaleString()}</pre>
                                        <pre className={styles.targetGaugeValueTarget}>{targetData.conversions.toLocaleString()}</pre>
                                    </div>
                                </div>
                                <p className={styles.targetResultGaugeItemPercent}>{percentages.conversions}%</p>
                            </div>
                            <div className={styles.targetResultGaugeItem}>
                                <p className={styles.targetResultGaugeItemTitle}>전환매출액</p>
                                <div>
                                    <div className={styles.targetGaugeBox}>
                                        <div className={styles[`targetGauge${mediaInfoId}`]}></div>
                                        <div className={styles[`targetGaugeBar${mediaInfoId}`]} style={{width: `${percentages.revenue}%`}}></div>
                                    </div>
                                    <div className={styles.targetGaugeValue}>
                                        <pre className={styles.targetGaugeValueZero}>0</pre>
                                        <pre className={styles[`targetGaugeValueText${mediaInfoId}`]} style={{width: `${percentages.revenue}%`}}>{data.revenue.toLocaleString()}</pre>
                                        <pre className={styles.targetGaugeValueTarget}>{targetData.revenue.toLocaleString()}</pre>
                                    </div>
                                </div>
                                <p className={styles.targetResultGaugeItemPercent}>{percentages.revenue}%</p>
                            </div>
                        </div>
                    </div>
                )}
                </>
            ) : (
                <div className={styles.targetInput}>
                    <div className={styles.targetInputTitle}>
                        <p>목표 성과를 입력해주세요.</p>
                        <div>
                            <button className={styles[`targetInputCancel${mediaInfoId}`]} onClick={() => setShowTargetInput(false)}>취소</button>
                            <button className={styles[`targetInputChange${mediaInfoId}`]} onClick={handleSubmit}>변경하기</button>
                        </div>
                    </div>
                    <div className={styles.targetInputContent}>
                        <div className={styles.targetInputContentItem}>
                            <p>전환수</p>
                            <input 
                                placeholder="1,000"
                                value={targetData.conversions === 0 ? '' : targetData.conversions.toLocaleString()}
                                onChange={(e) => handleInputChange('conversions', e.target.value)}
                            />
                        </div>
                        <div className={styles.targetInputContentItem}>
                            <p>전환매출액</p>
                            <input 
                                placeholder="1,000,000"
                                value={targetData.revenue === 0 ? '' : targetData.revenue.toLocaleString()}
                                onChange={(e) => handleInputChange('revenue', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

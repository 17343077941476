import { createSlice } from "@reduxjs/toolkit";

const initialStartDate = new Date();
// const initialStartDate = "2024-10-14"
const initialEndDate = new Date();
// const initialEndDate = "2024-10-20"
initialStartDate.setDate(initialStartDate.getDate() - 14);
initialEndDate.setDate(initialEndDate.getDate() - 8);

const formatDate = (dateString) => {
  const date = new Date(dateString); // 문자열을 Date 객체로 변환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

const initialState = {
  startDate: localStorage.getItem("startDate")
    ? localStorage.getItem("startDate")
    : formatDate(initialStartDate),
  endDate: localStorage.getItem("endDate")
    ? localStorage.getItem("endDate")
    : formatDate(initialEndDate),
};

const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    localDate: (state, action) => {
      state.startDate = action.payload.startDate;
      // state.startDate = "2024-10-14"
      state.endDate = action.payload.endDate;
      // state.endDate = "2024-10-20"
      localStorage.setItem("startDate", state.startDate); // startDate를 localStorage에 저장
      localStorage.setItem("endDate", state.endDate);
    },
  },
});

export const { localDate } = dateSlice.actions;
export const selectStartDate = (state) => state.date.startDate;
export const selectEndDate = (state) => state.date.endDate;

export default dateSlice.reducer;

import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Meta } from "../../../../assets/images/home/home-meta.svg"

export const MetaContent = () => {
    return (
        <div className={styles.metaContent}>
            <div className={styles.metaBox}>
                <div className={styles.metaImg}>
                    <Meta />
                </div>
                <p className={styles.metaTxt1}>메타 DA 광고 성과 분석 보고서</p>
                <p className={styles.metaTxt2}>
                    페이스북과 인스타그램의<br/>
                    DA 광고 성과를 확인해보세요!
                </p>
                <p className={styles.metaTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}
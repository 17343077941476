import React, { useState } from "react";
import { Link } from "react-router-dom";

import styles from "./scss/reportcreatemodal.module.scss"
import { ReactComponent as ModalClose } from "../../../assets/images/share/modal-close-green.svg"
import { ReactComponent as MediaLink } from "../../../assets/images/report/report-mediaLink.svg"
import { ReactComponent as AdvAdd } from "../../../assets/images/report/report-advAdd.svg"

import { AdvLink } from "./api/AdvLink";
import { ReportNmInputModal } from "./ReportNmInputModal";

export const ReportCreateModal = ({ onClose, media }) => {
    const [selectedOption, setSelectedOptions] = useState({}); 
    const [modalStep, setModalStep] = useState(1); // 모달 단계 관리

    const handleClose = () => {
        onClose();
    };

    const handleSelectOption = (id, option) => {
        setSelectedOptions((prevState) => ({
            ...prevState,
            [id]: option,
        }));
    };

    const handlePrevBtn = () => {
        setModalStep(1);
    }

    const handleNextBtn = () => {
        setModalStep(2);
    };

    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={handleClose} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>

                {/* modalStep에 따라 다른 모달을 보여줌 */}
                {modalStep === 1 ? (
                    <>
                        <div className={styles.modalTitle}>
                            <p>보고서 생성하기</p>
                        </div>
                        <div className={styles.mediaLinkBox}>
                            <MediaLink />
                            <div className={styles.txtBox}>
                                <p className={styles.txtBoxTxt1}>광고매체 연결</p>
                                <p className={styles.txtBoxTxt2}>보고서 이름 입력</p>
                            </div>
                        </div>
                        <div className={styles.advMediaBox}>
                            <div>
                                <p className={styles.advMediaBoxTxt1}>광고 매체 연결</p>
                                <p className={styles.advMediaBoxTxt2}>1개의 광고계정만 연동할 수 있어요!</p>
                            </div>
                            <Link to={"/datalink"}>
                                <AdvAdd />
                            </Link>
                        </div>
                        <AdvLink media={media} handleSelectOption={handleSelectOption} selectedOption={selectedOption} />
                        <div className={styles.modalBtnBox}>
                            <button className={styles.prevBtn} onClick={handleClose}>이전</button>
                            <button className={styles.nextBtn} onClick={handleNextBtn}>다음</button>
                        </div>
                    </>
                ) : (
                    <ReportNmInputModal onClose={onClose} handlePrevBtn={handlePrevBtn} selectedOption={selectedOption} media={media} />
                )}
            </div>
        </div>
    );
};

import React, { useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import "./signup.scss";

function MediaAdd({ mediaError, errors }) {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "mediaForms",
  });

  const handleAddClick = () => {
    append({ mediaCode: "", matchingId: "" });
  };

  return (
    <div className="media-matching">
      <div className="media-add">
        <p>매체 코드, 매칭ID</p>
        <button type="button" onClick={handleAddClick}>
          추가하기
        </button>
      </div>
      {fields.map((item, index) => (
        <div key={item.id} className="mediaAdd-form">
          <div className="media-select">
            <div className="mediaAdd-select-input">
              <select
                id={`mediaCode${index}`}
                {...register(`mediaForms.${index}.mediaCode`, {
                  required: "매체 코드를 선택해 주세요.",
                })}
                className="custom-select"
              >
                <option value="" hidden>
                  &nbsp;매체 코드
                </option>
                <option value="5">네이버</option>
                <option value="6">카카오</option>
                <option value="8">구글-하이엠triple2020</option>
                <option value="89">카카오키워드</option>
                <option value="107">구글-메이블</option>
                <option value="108">구글-하이엠(ju3070)</option>
                <option value="109">구글-디지털퍼스트</option>
                <option value="10">페이스북</option>
              </select>
              {errors.mediaForms &&
                errors.mediaForms[index] &&
                errors.mediaForms[index].mediaCode && (
                  <p style={{ color: "red" }}>
                    {errors.mediaForms[index].mediaCode.message}
                  </p>
                )}
              <input
                type="text"
                placeholder=" 매칭 ID"
                {...register(`mediaForms.${index}.matchingId`, {
                  required: "매칭ID를 입력해 주세요.",
                })}
              />
              {errors.mediaForms &&
                errors.mediaForms[index] &&
                errors.mediaForms[index].matchingId && (
                  <p style={{ color: "red" }}>
                    {errors.mediaForms[index].matchingId.message}
                  </p>
                )}
              {/* errors.mediaForms[0].matchingId.message */}
            </div>
            <button type="button" onClick={() => remove(index)}>
              삭제
            </button>
          </div>
        </div>
      ))}
      {/* {mediaError && (
        <p style={{ color: "red", paddingTop: 5, width:"60%", marginLeft: "17px" }}>{mediaError}</p>
      )} */}
    </div>
  );
}

export default MediaAdd;

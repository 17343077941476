import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { KakaoKeywordLinkApi } from "../../../../../api/socialLogin/KakaoApi";

const { Kakao } = window;

//  그냥 여기서 Code를 가져올 수 있게 설정해야할 듯
const KakaoMomentLink = forwardRef((props, ref) => {
    const CLIENT_ID = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
    const REDIRECT_URL = process.env.REACT_APP_MOMENT_REDIRECT_URI
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/business/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code&scope=biz_account_email,moment_create,moment_management,moment_delete&resource_ids=moment:*`
    console.log(KAKAO_AUTH_URL);
    
    const triggerMomentLogin = () => {
        window.location.href = KAKAO_AUTH_URL;
    };

    useImperativeHandle(ref, () => ({
        triggerMomentLogin: triggerMomentLogin,
    }));

});

export default KakaoMomentLink;

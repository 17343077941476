import React, { useState, useEffect } from "react";
import "./modal.scss";
const Modal = ({ show, handleClose, companion }) => {
  const [reasonText, setReasonText] = useState("")
  
  useEffect(() => {
    setReasonText(companion)
  }, [companion]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-content">
        <div className="modal-content-title">
          <h2>반려 사유</h2>
        </div>
        <div className="modal-content-description">
          <p>{reasonText}</p>
          <p>광고주를 다시 등록해주세요.</p>
        </div>
        <div className="modal-content-button">
          <button>광고주 등록하러 가기</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from 'react';

import styles from '../scss/deviceimpression.module.scss';

import { CostDeviceImpressionApi } from '../../../../api/report/cost/CostDeviceImpressionApi';

import { DevicePieChart } from '../../../utils/chart/DevicePieChart';
import Loading from '../../../utils/loading/Loading';

export const DeviceImpression = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("cost");

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostDeviceImpressionApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const color1 = () => {
        if (mediaInfoId === 5) {
            return 'rgba(0, 176, 80, 1)';
        } else if (mediaInfoId === 89) {
            return 'rgba(255, 180, 18, 1)';
        } else if (mediaInfoId === 100) {
            return 'rgba(0, 114, 240, 1)';
        }
        return ;
    };

    const color2 = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 0.2)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 0.2)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 0.2)";
        }
        return ;
    };

    const chartData = data.data.map(item => ({
        device: item.device_type, // day_week 값을 사용
        value: selectedValue === "impression" ? item.impression_cost :
               selectedValue === "click" ? item.cost_click :
               selectedValue === "conversion" ? item.conversion_cost :
               item.tot_cost
    }));

    return (
        <div className={styles.deviceImpression}>
            <div className={styles.deviceImpressionTitle}>
                <p>PC/MO 별 광고비</p>
                <div className={styles[`deviceImpressionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="cost">총 광고비</option>
                        <option value="impression">노출당 비용</option>
                        <option value="click">클릭당 비용</option>
                        <option value="conversion">전환당 비용</option>
                    </select>
                </div>
            </div>
            <div className={styles.deviceImpressionContent}>
                <DevicePieChart data={chartData} color1={color1()} color2={color2()} selectedValue={selectedValue} />
            </div>
        </div>
    )
}
import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const CampaignLine = ({ data, selectedData, mediaInfoId }) => {
    const borderColor = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 1)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 1)";
        }
        return ;
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "top",
                align: "center",
                margin: {
                    bottom: 10,
                },
                labels: {
                    // usePointStyle: true,
                    boxWidth: 30,
                    boxHeight: 0,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "bold",
                    },
                },
            },
            datalabels: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "bold"
                    },
                    color: '#000'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "bold"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
        },
    };
    
    const chartData = {
        labels: data[0].data.map(item => item.date),
        datasets: data.map((campaign, index) => ({
            label: campaign.campaign_name, // 캠페인 이름
            data: campaign.data.map(item => item[selectedData]), // 클릭 수
            borderColor: index === 0 ? borderColor() : ( // 첫 번째 캠페인에만 borderColor 함수 사용
                index === 1 ? 'rgba(249, 185, 112, 1)' :
                index === 2 ? 'rgba(255, 133, 214, 1)' :
                'rgba(83, 183, 255, 1)' // D 캠페인
            ),
            backgroundColor: 'rgba(255, 99, 132, 0.5)', // 기본 배경색, 필요에 따라 수정 가능
            borderWidth: 2,
            pointRadius: 0,
        })),
    };

    return (
        <div style={{ width: '525px', height: '279px' }}>
            <Line options={options} data={chartData} />
        </div>
    );
}
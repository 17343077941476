import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./signupCopy.module.scss";
import Navbar from "../../shared/header/Header";
import axios from "../../api/axios";
import { path } from "../../store/PathSlice";
import Sectors from "./inputBox/Sectors";
import Agreements from "./inputBox/Agreements";

import { ReactComponent as SingupLogo } from "../../assets/images/signup/signup-logo.svg";
import { ReactComponent as AdfLogo1 } from "../../assets/images/signup/adf-info-1.svg";
import { ReactComponent as AdfLogo2 } from "../../assets/images/signup/adf-info-2.svg";
import { ReactComponent as AdfLogo3 } from "../../assets/images/signup/adf-info-3.svg";
import { ReactComponent as NoShowPwMark } from "../../assets/images/signup/noShowPwMark.svg";
import { ReactComponent as ShowPwMark } from "../../assets/images/signup/showPwMark.svg";
import { ReactComponent as KakaoMark } from "../../assets/images/signup/kakaoCircle.svg";
import { ReactComponent as NaverMark } from "../../assets/images/signup/naverCircle.svg";
import { ReactComponent as GoogleMark } from "../../assets/images/signup/googleCircle.svg";

function Signup() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const url = location.pathname.split("/").pop();
    dispatch(path(url));

    const [showPw1, setShowPw1] = useState({
        type: "password",
        visible: false,
    });
    const [showPw2, setShowPw2] = useState({
        type: "password",
        visible: false,
    });

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [idCheck, setIdCheck] = useState(false);

    const [agreements, setAgreements] = useState({
        termsAgree: false,
        privacyAgree: false,
        adsAgree: false,
    });

    const methods = useForm({
        defaultValues: {
            username: "",
            password: "",
            adv_name: "",
        },
    });

    const [errMsg, setErrMsg] = useState({
        username: "",
        password: "",
        password2: "",
        adv_name: "",
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = methods;

    const password = watch("password");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value); // react-hook-form에 값 설정
    };

    const handleCheckDuplicate = async () => {
        const username = methods.getValues("username"); // 현재 입력된 username 값 가져오기

        try {
            const response = await axios.get(`common/register/`, {
                params: { username },
            });

            if (response.data.exists) {
                alert("이미 사용 중인 이메일입니다.");
            } else {
                alert("사용 가능한 이메일입니다.");
                setIdCheck(true);
            }
        } catch (error) {
            console.error("중복 확인 중 오류 발생:", error);
            alert("중복 확인 중 오류가 발생했습니다.");
        }
    };

    const CustomhandleSubmit = async (data) => {
        console.log(data)
        if (!agreements.termsAgree || !agreements.privacyAgree) {
            alert("이용약관 및 개인정보 처리방침에 동의하셔야 회원가입이 가능합니다.");
            return;
        }

        if (!idCheck) {
            alert("아이디 중복확인을 해주세요.")
            return;
        }
        navigate("/email-verify", { state: {username: data.username} });

        try {
            const response = await axios.post("common/register/", {
                ...data,
                selectedCategory: selectedCategory.value,
                selectedSubcategory: selectedSubcategory.value,
                termsAgree: agreements.termsAgree,
                privacyAgree: agreements.privacyAgree,
                adsAgree: agreements.adsAgree,
            });

            setErrMsg({
                username: "",
                password: "",
                password2: "",
                adv_name: "",
            });
            
            
        } catch (error) {
            setErrMsg((prevState) => ({
                ...prevState,
                username: error.response.data.username || "",
                password: error.response.data.password || "",
            }));

            console.error("Error signing up:", error);

            if (error.response && error.response.data) {
                if (error.response.data.password) {
                    alert(error.response.data.password);
                    setErrMsg({});
                }
                if (error.response.data.username) {
                    alert(error.response.data.username);
                }
            } else {
                alert("회원가입이 실패했습니다.");
            }
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {});
        return () => subscription.unsubscribe();
    }, [watch]);

    const handleShowPw1 = (e) => {
        setShowPw1((prevState) => ({
            type: prevState.visible ? "password" : "text",
            visible: !prevState.visible,
        }));
    };

    const handleShowPw2 = (e) => {
        setShowPw2((prevState) => ({
            type: prevState.visible ? "password" : "text",
            visible: !prevState.visible,
        }));
    };

    const handleSnsLogin = () => {
        alert("현재 준비 중인 기능이에요!");
    };

    return (
        <div className={styles.mainBox}>
            <div className={styles.leftBox}>
                <div className={styles.adfInfo1}>
                    <div>
                        <SingupLogo />
                        <h3>에서</h3>
                    </div>
                    <h3>다양한 종류의 데이터를 쉽고 정확하게 분석해 보세요!</h3>
                </div>
                <div className={styles.adfInfo2}>
                    <AdfLogo1 />
                    <div>
                        <h3>다양한 매체의 데이터를 한 번에 확인할 수 있어요.</h3>
                        <h5>
                            여러 매체의 데이터를 통합적으로 분석하여 광고 성과를 더욱
                            정확하게 파악할 수 있습니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo3}>
                    <AdfLogo2 />
                    <div>
                        <h3>수많은 데이터를 기반으로 한 예측 모델을 경험해 보세요.</h3>
                        <h5>
                            광고성과를 예측하여 최적의 광고 전략을 수립할 수 있도록
                            도와줍니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo4}>
                    <AdfLogo3 />
                    <div>
                        <h3>시각적 자료를 활용해 더욱 직관적인 데이터를 제공합니다.</h3>
                        <h5>
                            데이터를 한눈에 파악할 수 있어, 광고 전략을 더욱 쉽게 수립할 수
                            있습니다.
                        </h5>
                    </div>
                </div>
            </div>
            <div className={styles.signBox}>
                <h1 className={styles.titleText}>회원가입하기</h1>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(CustomhandleSubmit)}
                        className={styles.signFormBox}
                    >
                        <div className={styles.idInput}>
                            <div>
                                <input
                                    type="text"
                                    placeholder="이메일을 입력해주세요."
                                    {...register("username", {
                                        required: "아이디를 입력해주세요",
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message:
                                                "유효한 이메일 주소를 입력해 주세요.",
                                        },
                                    })}
                                    onChange={handleChange}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={handleCheckDuplicate} // 중복 확인 버튼 클릭 시 함수 실행
                            >
                                중복 확인
                            </button>
                            {errors.username && (
                                <p style={{ color: "red" }}>
                                    {errors.username.message}
                                </p>
                            )}
                        </div>
                        <div className={styles.pwBox}>
                            <div className={styles.pwInputBox}>
                                <div className={styles.pwInput}>
                                    <input
                                        type={showPw1.type}
                                        placeholder="비밀번호를 입력해주세요."
                                        {...register("password", {
                                            required:
                                                "비밀번호를 입력해 주세요",
                                            pattern: {
                                                value:
                                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                message:
                                                    "영문, 숫자, 특수기호를 포함 8자 이상 입력해 주세요.",
                                            },
                                        })}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div
                                    className={styles.pwMark}
                                    onClick={handleShowPw1}
                                >
                                    {showPw1.visible ? (
                                        <ShowPwMark />
                                    ) : (
                                        <NoShowPwMark />
                                    )}
                                </div>
                                {errors.password && (
                                    <p style={{ color: "red" }}>
                                        {errors.password.message}
                                    </p>
                                )}
                            </div>
                            <div className={styles.pwCheck}>
                                <span>&#10004; 영문, 숫자, 특수 기호 포함</span>
                                <span>&#10004; 8자 이상</span>
                            </div>
                        </div>
                        <div className={styles.pwInputBox}>
                            <div className={styles.pwInput}>
                                <input
                                    type={showPw2.type}
                                    placeholder="비밀번호를 한번 더 입력해주세요."
                                    {...register("password2", {
                                        required: "비밀번호를 확인해주세요",
                                        validate: (value) =>
                                            value === password ||
                                            "비밀번호가 일치하지 않습니다.",
                                    })}
                                    onChange={handleChange}
                                />
                            </div>
                            <div
                                className={styles.pwMark}
                                onClick={handleShowPw2}
                            >
                                {showPw2.visible ? (
                                    <ShowPwMark />
                                ) : (
                                    <NoShowPwMark />
                                )}
                            </div>
                            {errors.password2 && (
                                <p style={{ color: "red" }}>
                                    {errors.password2.message}
                                </p>
                            )}
                        </div>
                        <div className={styles.advBox}>
                            <div className={styles.advInput}>
                                <input
                                    type="text"
                                    placeholder="광고주명을 입력해주세요."
                                    {...register("adv_name", {
                                        required: "광고주명을 입력해 주세요",
                                    })}
                                    onChange={handleChange}
                                />
                                {errors.adv_name && (
                                    <p style={{ color: "red" }}>
                                        {errors.adv_name.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <Sectors
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            selectedSubcategory={selectedSubcategory}
                            setSelectedSubcategory={setSelectedSubcategory}
                        />
                    </form>
                </FormProvider>
                <Agreements
                    checked={agreements}
                    onChange={setAgreements}
                />
                <div className={styles.signUpBox}>
                    <button onClick={handleSubmit(CustomhandleSubmit)}>
                        회원가입
                    </button>
                </div>
                <div className={styles.snsLogin}>간편 회원가입</div>
                <div className={styles.snsMark}>
                    <button
                        onClick={handleSnsLogin}
                        className={styles.googleBtn}
                    >
                        <GoogleMark />
                        <p>구글로 간편 회원가입</p>
                    </button>
                    <button
                        onClick={handleSnsLogin}
                        className={styles.naverBtn}
                    >
                        <NaverMark />
                        <p>네이버로 간편 회원가입</p>
                    </button>
                    <button
                        onClick={handleSnsLogin}
                        className={styles.kakaoBtn}
                    >
                        <KakaoMark />
                        <p>카카오로 간편 회원가입</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Signup;

import axios from "../../axios";

export const KeywordDetailAnalysisDataApi = async (media, startDate, endDate, accountId, page, row, orderBy ) => {
    const path = `${media}/${accountId}/keywords/detail-analysis`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                page_size: page,
                row_num: row,
                order_by: orderBy
            }
        });
        return data;
    } catch (error) {
        console.error("성과 상세분석 데이터 오류 발생:", error);
        throw error;
    }
};

export const KeywordDetailAnalysisTotalApi = async (media, startDate, endDate, accountId ) => {
    const path = `${media}/${accountId}/keywords/detail-analysis-total`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("성과 상세분석 총합 데이터 오류 발생:", error);
        throw error;
    }
};

export const KeywordDetailAnalysisDownloadApi = async (media, startDate, endDate, accountId, fields, orderBy, responseType) => {
    const path = `${media}/${accountId}/keywords/detail-analysis-download`;
    try {
        const response = await axios.get(path, {
            responseType: responseType,
            params: {
                start_dt: startDate,
                end_dt: endDate,
                fields: fields,
                order_by: orderBy
            }
        });
        return response;
    } catch (error) {
        console.error("성과 상세분석 엑셀 다운로드 오류 발생:", error);
        throw error;
    }
};
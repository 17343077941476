import React from 'react'

import styles from './reporttable.module.scss'

import CustomPagination from '../pagination/Pagination'
import {ReactComponent as SortMark} from '../../../assets/images/share/sort.svg'
import {ReactComponent as UpSortMark} from '../../../assets/images/share/upsort.svg'
import {ReactComponent as DownSortMark} from '../../../assets/images/share/downsort.svg'

export const FunnelTable = ({ defaultHeaders, headers, items, totalItems, pageData, handlePageChange, currentPage, mediaInfoId, adLevel, onCampaignClick, onGroupClick }) => {
    const combinedItems = [totalItems, ...items];

    const handleCampaignClick = (campaignId) => {
        if (typeof onCampaignClick === 'function') {
            onCampaignClick(campaignId);
        }
    };

    const handleGroupClick = (groupId) => {
        console.log("그룹클릭")
        if (typeof onGroupClick === 'function') {
            onGroupClick(groupId);
        }
    }

    return (
        <>
            <div className={styles.funnelTable}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableHeaderRow}>
                        {Array.isArray(defaultHeaders) && defaultHeaders.length > 0 && (
                            defaultHeaders.map((item, index) => (
                                <div key={`default-${index}`}>{item}</div>
                            ))
                        )}
                        {Object.keys(headers).filter(key => headers[key]).map(key => (
                            <div key={key}>{key}</div>
                        ))}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {combinedItems.map((row, index) => (
                        <div key={index} className={styles.tableBodyRow}>
                            {defaultHeaders.map((header, headerIndex) => {
                                const displayValue = typeof row[header] === 'number' 
                                    ? (header === "광고비" ? `₩${row[header].toLocaleString()}` : row[header].toLocaleString()) 
                                    : row[header];
                                return (
                                    <div 
                                        key={`default-${headerIndex}`} 
                                        className={index === 0 || adLevel === '키워드' ? styles.firstLinkTxt : styles.linkTxt} 
                                        onClick={header === "캠페인" ? () => handleCampaignClick(row.campaignId) : header === "광고그룹" ? () => handleGroupClick(row.groupId) : undefined}
                                    >
                                        {displayValue}
                                    </div>
                                );
                            })}
                            {Object.keys(headers).filter(key => headers[key]).map(key => {
                                const displayValue = typeof row[key] === 'number' 
                                ? (key === "매출전환액" ? `₩${row[key].toLocaleString()}` 
                                    : (key === "클릭당 비용" ? `₩${row[key].toLocaleString()}` 
                                    : (["클릭률", "전환율", "광고수익률"].includes(key) ? `${row[key].toFixed(0)}%` : row[key].toLocaleString())))
                                : row[key];
                                return (
                                    <div key={key}>{displayValue}</div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <CustomPagination 
                    total={pageData.count} 
                    views={pageData.page_size}
                    handlePageChange={handlePageChange}
                    page={currentPage}
                    mediaInfoId={mediaInfoId}
                />
            </div>
        </>
    );
};
import React, { useState, useEffect } from 'react'

import styles from "../scss/keywordperformance.module.scss"
import {ReactComponent as ExcelDownloadNaver} from '../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../assets/images/report/report-excel-down-google.svg'

import { KeywordDetailAnalysisDataApi, KeywordDetailAnalysisTotalApi, KeywordDetailAnalysisDownloadApi } from '../../../../api/report/keyword/KeywordDetailAnalysisApi'

import { ReportTable } from '../../../utils/table/ReportTable'
import Loading from '../../../utils/loading/Loading'

export const KeywordPerformance = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("tot_cost DESC");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': false,
        '클릭당 비용': false,
        '매출전환액': true,
        '전환율': false,
        '전환수': true,
        '광고수익률': true,
        '평균노출순위': false
    });
    const [prevDateRange, setPrevDateRange] = useState({ startDate: null, endDate: null });

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const [detailAnalysisResponse] = await Promise.all([
                KeywordDetailAnalysisDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy)
            ]);
            setData(detailAnalysisResponse);
            
            if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
                const totalDetailAnalysisResponse = await KeywordDetailAnalysisTotalApi(mediaName(), startDate, endDate, adAccountsId);
                setTotal(totalDetailAnalysisResponse);
                setPrevDateRange({ startDate, endDate });
            }
            
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, row, orderBy]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "키워드": item.keyword_name,
        "PC/MO": item.device_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "클릭당 비용": item.cost_click,
        "매출전환액": item.tot_conversion_amount,
        "전환율": item.conversion_rate,
        "전환수": item.tot_conversion_count,
        "광고수익률": item.roas,
        "평균노출순위": item.tot_avg_rank
    }))

    const transformedTotalData = () => {
        return {
            "키워드": "총합",
            "PC/MO": "-",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "클릭당 비용": total.data.cost_click,
            "매출전환액": total.data.tot_conversion_amount,
            "전환율": total.data.conversion_rate,
            "전환수": total.data.tot_conversion_count,
            "광고수익률": total.data.roas,
            "평균노출순위": "-"
        };
    };

    const toggleButton = (buttonName) => {
        setSelectedButtons(prev => ({
            ...prev,
            [buttonName]: !prev[buttonName]
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 5:
                return <ExcelDownloadNaver />;
            case 89:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            default:
                return ; // 기본값
        }
    };

    const handleDownload = async () => {
        let fields = [];
    
        // selectedButtons가 true인 버튼에 따라 fields 설정
        if (Object.values(selectedButtons).every(value => value === true)) {
            fields.push('all'); // 모든 버튼이 true일 경우
        } else {
            // selectedButtons에서 true인 항목만 fields에 추가
            for (const [key, value] of Object.entries(selectedButtons)) {
                if (value) {
                    switch (key) {
                        case '클릭률':
                            fields.push('click_rate');
                            break;
                        case '클릭당 비용':
                            fields.push('cost_click');
                            break;
                        case '매출전환액':
                            fields.push('tot_conversion_amount');
                            break;
                        case '전환율':
                            fields.push('conversion_rate');
                            break;
                        case '전환수':
                            fields.push('tot_conversion_count');
                            break;
                        case '광고수익률':
                            fields.push('roas');
                            break;
                        case '평균노출순위':
                            fields.push('tot_avg_rank');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click');
    
        try {
            const response = await KeywordDetailAnalysisDownloadApi(
                mediaName(),
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob'
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    const sampleData = [
        { '키워드': "총합", '광고비': 10000, '노출수': 150030, '클릭수': 10089, '클릭률': 1.9, '클릭당 비용': 10678, '매출전환액': 2000867, '전환율': 3.9, '전환수': 5432, '광고수익률': 2003121, '평균노출순위': '-'  },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1000, '노출수': 5000, '클릭수': 100, '클릭률': 2, '클릭당 비용': 10, '매출전환액': 2000, '전환율': 5, '전환수': 5, '광고수익률': 200, '평균노출순위': 2 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1200, '노출수': 5500, '클릭수': 110, '클릭률': 2.2, '클릭당 비용': 11, '매출전환액': 2200, '전환율': 5.5, '전환수': 6, '광고수익률': 180, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1300, '노출수': 6000, '클릭수': 120, '클릭률': 2.4, '클릭당 비용': 12, '매출전환액': 2400, '전환율': 6, '전환수': 7, '광고수익률': 170, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1400, '노출수': 6500, '클릭수': 130, '클릭률': 2.6, '클릭당 비용': 13, '매출전환액': 2600, '전환율': 6.5, '전환수': 8, '광고수익률': 160, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1500, '노출수': 7000, '클릭수': 140, '클릭률': 2.8, '클릭당 비용': 14, '매출전환액': 2800, '전환율': 7, '전환수': 9, '광고수익률': 150, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1600, '노출수': 7500, '클릭수': 150, '클릭률': 3, '클릭당 비용': 15, '매출전환액': 3000, '전환율': 7.5, '전환수': 10, '광고수익률': 140, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1700, '노출수': 8000, '클릭수': 160, '클릭률': 3.2, '클릭당 비용': 16, '매출전환액': 3200, '전환율': 8, '전환수': 11, '광고수익률': 130, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1800, '노출수': 8500, '클릭수': 170, '클릭률': 3.4, '클릭당 비용': 17, '매출전환액': 3400, '전환율': 8.5, '전환수': 12, '광고수익률': 120, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1000, '노출수': 5000, '클릭수': 100, '클릭률': 2, '클릭당 비용': 10, '매출전환액': 2000, '전환율': 5, '전환수': 5, '광고수익률': 200, '평균노출순위': 3 },
        { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1200, '노출수': 5500, '클릭수': 110, '클릭률': 2.2, '클릭당 비용': 11, '매출전환액': 2200, '전환율': 5.5, '전환수': 6, '광고수익률': 180, '평균노출순위': 3 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1300, '노출수': 6000, '클릭수': 120, '클릭률': 2.4, '클릭당 비용': 12, '매출전환액': 2400, '전환율': 6, '전환수': 7, '광고수익률': 170 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1400, '노출수': 6500, '클릭수': 130, '클릭률': 2.6, '클릭당 비용': 13, '매출전환액': 2600, '전환율': 6.5, '전환수': 8, '광고수익률': 160 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1500, '노출수': 7000, '클릭수': 140, '클릭률': 2.8, '클릭당 비용': 14, '매출전환액': 2800, '전환율': 7, '전환수': 9, '광고수익률': 150 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1600, '노출수': 7500, '클릭수': 150, '클릭률': 3, '클릭당 비용': 15, '매출전환액': 3000, '전환율': 7.5, '전환수': 10, '광고수익률': 140 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1700, '노출수': 8000, '클릭수': 160, '클릭률': 3.2, '클릭당 비용': 16, '매출전환액': 3200, '전환율': 8, '전환수': 11, '광고수익률': 130 },
        // { '키워드':'키워드A', 'PC/MO':'PC', '광고비': 1800, '노출수': 8500, '클릭수': 170, '클릭률': 3.4, '클릭당 비용': 17, '매출전환액': 3400, '전환율': 8.5, '전환수': 12, '광고수익률': 120 },
    ];

    return (
        <div className={styles.keywordPerformance}>
            <div className={styles.keywordPerformanceTitle}>키워드별 성과상세 분석</div>
            <div className={styles.dataSelectBox}>
                <div className={styles[`dataSelectButton${mediaInfoId}`]}>
                    {Object.keys(selectedButtons).map((buttonName) => (
                        <button
                            key={buttonName}
                            className={selectedButtons[buttonName] ? styles.active : ''}
                            onClick={() => toggleButton(buttonName)}
                        >
                            {buttonName}
                        </button>
                    ))}
                </div>
                <button className={styles.excelDownload} onClick={handleDownload}>
                    {getExcelDownloadIcon()}
                </button>
            </div>
            <ReportTable 
                defaultHeaders={['키워드','PC/MO','광고비','노출수','클릭수']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
            />
        </div>
    )
}
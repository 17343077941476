import { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./login.scss";
import { ReactComponent as KakaoMark } from "../../assets/images/signup/kakaoCircle.svg";
import { ReactComponent as NaverMark } from "../../assets/images/signup/naverCircle.svg";
import { ReactComponent as GoogleMark } from "../../assets/images/signup/googleCircle.svg";
import { ReactComponent as LogoMark } from "../../assets/images/share/loginLogo.svg";
import { ReactComponent as NoShowPwMark } from '../../assets/images/signup/noShowPwMark.svg';
import { ReactComponent as ShowPwMark } from '../../assets/images/signup/showPwMark.svg';

import { setSelected, resetSelected, setMenu, resetMenu } from "../../store/SelectedSlice";
import { clearUser } from "../../store/UserSlice";
import { resetPath } from "../../store/PathSlice";
import { resetCode } from "../../store/SocialSlice";

import { LoginAPI } from "../../api/accounts/auth";
import { checkTokenAPI } from "../../api/accounts/checkToken";
import { getAccessToken } from "../../api/axios";

import GoogleLogin from "../../components/login/google/GoogleLogin";
import KakaoLogin from "../../components/login/kakao/KakaoLogin";
import { NaverLogin } from "../../components/login/naver/NaverLogin";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPw, setShowPw] = useState({
    type: "password",
    visible: false
  });
  const [autoLogin, setAutoLogin] = useState(false);
  const googleLoginRef = useRef();
  const kakaoLoginRef = useRef();
  const naverLoginRef = useRef();
  const dispatch = useDispatch();

  dispatch(resetPath());
  dispatch(clearUser());
  dispatch(resetMenu());
  dispatch(resetSelected());
  dispatch(resetCode());

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await LoginAPI(username, password, dispatch);
    if (response && response.errormsg) {
      alert(response.errormsg);
    } else {
      dispatch(setSelected("ga4Data"));
      dispatch(setMenu("dashboard"));
      navigate("/home");
    }
  };

  const handleSnsLogin = () => {
    alert("현재 준비 중인 기능이에요!");
  };

  const chekcBoxOnChangeEvent = (e) => {
    setAutoLogin(e.target.checked);
    localStorage.setItem("autoLogin", e.target.checked);
    console.log(localStorage.getItem("autoLogin"));
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessToken();
      if (accessToken) {
        const response = await checkTokenAPI();
        if (
          response.status === 200 &&
          localStorage.getItem("autoLogin") === "true"
        ) {
          dispatch(setSelected("ga4Data"));
          navigate("/home");
        } else {
          localStorage.setItem("autoLogin", "false");
        }
      } else {
        localStorage.setItem("autoLogin", "false");
      }
    };
    fetchData();
  }, []);

  const handleShowPw = (e) => {
    setShowPw(() => {
      if (!showPw.visible) {
        return { type: "text", visible: true};
      } else {
        return { type: "password", visible: false};
      }
    });
  }

  return (
    <div className="main">
      <div className="login-box">
        <div className="logo">
          <LogoMark />
        </div>
        <form onSubmit={handleLogin} className="input-box">
          <div className="id-box">
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              placeholder="이메일을 입력해주세요"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="pw-box">
            <input
              type={showPw.type}
              id="password"
              name="password"
              value={password}
              placeholder="비밀번호를 입력해주세요"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="pw-mark" onClick={handleShowPw}>
              {showPw.visible ? <ShowPwMark />:<NoShowPwMark />}
            </div>
          </div>
          <button type="submit">로그인</button>
        </form>
        <div className="link-box">
          {/* 자동로그인
          <input
            className="login-checked"
            type="checkbox"
            checked={autoLogin}
            onChange={(e) => chekcBoxOnChangeEvent(e)}
            style={{ width: 20, height: 20 }}
          /> */}
          <Link to="/signupCopy">회원가입</Link>
          <a>|</a>
          <Link to="/pw-find">아이디 비밀번호 찾기</Link>
        </div>
        <div className="sns-login">간편 로그인</div>
        <div className="sns-mark">
          <>
            <GoogleLogin ref={googleLoginRef}/>
            <button onClick={() => googleLoginRef.current.triggerGoogleLogin()} className="google-btn">
              <GoogleMark />
              <p>구글로 간편 로그인</p>
            </button>
          </>
          <>
            <NaverLogin ref={naverLoginRef} />
            <button onClick={() => naverLoginRef.current.triggerNaverLogin()} className="naver-btn">
              <NaverMark />
              <p>네이버로 간편 로그인</p>
            </button>
          </>
          <>
            <KakaoLogin ref={kakaoLoginRef} />
            <button onClick={() => kakaoLoginRef.current.triggerKakaoLogin()} className="kakao-btn">
              <KakaoMark />
              <p>카카오로 간편 로그인</p>
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";

import styles from "./reportdetail.module.scss";
import { ReactComponent as Img1 } from "../../../assets/images/report/report-detail-1.svg";
import { ReactComponent as Img2 } from "../../../assets/images/report/report-detail-2.svg";
import { ReactComponent as Img3 } from "../../../assets/images/report/report-detail-3.svg";
import { ReactComponent as Img4 } from "../../../assets/images/report/report-detail-4.svg";
import { ReactComponent as Img5 } from "../../../assets/images/report/report-detail-5.svg";
import { ReactComponent as LeftBtn } from "../../../assets/images/report/report-leftBtn.svg";
import { ReactComponent as RightBtn } from "../../../assets/images/report/report-rightBtn.svg";


import { ReportPreviewNaver } from "./naver/ReportPreviewNaver";
import { ReportPreviewGoogle } from "./google/ReportPreviewGoogle";
import { ReportPreviewKakao } from "./kakao/ReportPreviewKakao";

export const ReportDetail = ({ openDetails }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalImages = 3;

    const handleLeftClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));
    };

    const handleRightClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === totalImages - 1 ? 0 : prevIndex + 1));
    };

    const value = openDetails;

    return (
        <div className={styles.main}>
            <div className={styles.btnBox}>
                <div className={styles.btnNum}>
                    {value !== "default" ? `${currentIndex + 1} / ${totalImages}` : "0 / 0"}
                </div>
                <div className={styles.btnImgBox}>
                    <div className={styles.btnImg} style={{ paddingLeft: "7px" }} onClick={handleLeftClick}>
                        <LeftBtn />
                    </div>
                    <div className={styles.btnImg} style={{ paddingLeft: "9px" }} onClick={handleRightClick}>
                        <RightBtn />
                    </div>
                </div>
            </div>
            {value === "default" && (
                <div className={styles.imgBox}>
                    <div className={styles.imgContainer}>
                        <Img1 />
                        <Img3 />
                        <Img5 />
                    </div>
                    <div className={styles.imgContainer}>
                        <Img2 />
                        <Img4 />
                        <Img1 />
                    </div>
                    <div className={styles.imgTxt}>
                        <p>자세히 보기를 클릭해</p>
                        <p>보고서를 미리 확인해보세요!</p>
                    </div>
                </div>
            )}
            {/* {value === "total" && (
                <div className={styles.reportPreview}>
                    <ReportPreviewTotal currentIndex={currentIndex} />
                </div>
            )} */}
            {value === "naver" && (
                <div className={styles.reportPreview}>
                    <ReportPreviewNaver currentIndex={currentIndex} />
                </div>
            )}
            {value === "kakao" && (
                <div className={styles.reportPreview}>
                    <ReportPreviewKakao currentIndex={currentIndex} />
                </div>
            )}
            {/* {value === "facebook" && (
                <div className={styles.reportPreview}>
                    <ReportPreviewFacebook currentIndex={currentIndex} />
                </div>
            )} */}
            {value === "google" && (
                <div className={styles.reportPreview}>
                    <ReportPreviewGoogle currentIndex={currentIndex} />
                </div>
            )}
        </div>
    );
};

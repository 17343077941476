import axios from "../../axios";

export const CostTrendApi = async ( media, startDate, endDate, accountId, selectedValue ) => {
    const path = `${media}/${accountId}/costs/trand/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                period_type: selectedValue,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("비용 추세 오류 발생:", error);
        throw error;
    }
};
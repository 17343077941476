import axios from "../axios";

export const ChangeReportNameApi = async (incrementId, newName) => {
    try {
        const { data }  = await axios.patch("/report/create/", { 
            incrementId: incrementId,
            report_nm: newName
        });
        return data;
    } catch (error) {
        throw error;
    }
};
import React, { useState, useEffect } from 'react';

import styles from '../scss/dateconversion.module.scss';

import { KeywordDateConversionApi } from '../../../../api/report/keyword/KeywordDateConversionApi';

import { YaxisBarChart } from '../../../utils/chart/YaxisBarChart';
import Loading from '../../../utils/loading/Loading';

export const DateConversion = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("count"); // 선택된 값 상태 추가

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordDateConversionApi(mediaName(), startDate, endDate, adAccountsId); // 선택된 값 전달
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }
    
    const testData = [
        {"day": "월요일", "conversion_amount": 100000},
        {"day": "화요일", "conversion_amount": 200000},
        {"day": "수요일", "conversion_amount": 300000},
        {"day": "목요일", "conversion_amount": 400000},
        {"day": "금요일", "conversion_amount": 500000},
        {"day": "토요일", "conversion_amount": 600000},
        {"day": "일요일", "conversion_amount": 700000},
    ]

    const chartData = data.data.map(item => ({
        day: item.day_week, // day_week 값을 사용
        value: selectedValue === "amount" ? item.tot_conversion_amount :
               selectedValue === "roas" ? item.roas :
               item.tot_conversion_count
    }));

    const barLabel = () => {
        if (selectedValue === "count") {
            return "전환수";
        } else if (selectedValue === "amount") {
            return "매출전환액";
        } else if (selectedValue === "roas") {
            return "광고수익률";
        }
    }

    return (
        <div className={styles.dateConversion}>
            <div className={styles.dateConversionTitle}>
                <p>요일별 전환</p>
                <div className={styles[`dateConversionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="count">전환수</option>
                        <option value="amount">매출전환액</option>
                        <option value="roas">광고수익률</option>
                    </select>
                </div>
            </div>
            <div className={styles.dateConversionContent}>
                <YaxisBarChart data={chartData} barLabel={barLabel()} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    )
}

import axios from "../axios"

export const EmailCertApi = async (username) => {
    console.log(username)
    try {
        const { data } = await axios.get("common/email-cert", {
            params: {
                username: username
            }
        });
        return data
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error)
        return null;
    }
}
import axios from "../axios"

export const KakaoLoginApi = async (authObj) => {
    try {
        const { data } = await axios.post('kakao/login/',
            { authObj },
            { headers: { 'Content-Type': 'application/json' } }
        );
        return data; // 가져온 데이터 반환
    } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
        return null;
    }
}
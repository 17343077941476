import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./signup.scss";
import Navbar from "../../shared/header/Header";
import MediaAdd from "./mediaAdd";
import axios from "../../api/axios";
import { path } from "../../store/PathSlice";

function Signup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split("/").pop();
  dispatch(path(url))
  
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    password2: "",
    advertisers: "",
    marketer: "",
    marketerPart: "",
    propertyId: "",
    mediaForms: [],
  });
  const [errMsg, setErrMsg] = useState({
    username: "",
    password: "",
    password2: "",
    advertisers: "",
    marketer: "",
    marketerPart: "",
    propertyId: "",
  });
  const [mediaError, setMediaError] = useState("");

  const methods = useForm({
    defaultValues: formData,
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const password = watch("password");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value); // react-hook-form에 값 설정
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const CustomhandleSubmit = async (data) => {
    try {
      const response = await axios.post("common/register/", data);
      alert(
        "정상적으로 승인이 요청되었습니다. 승인은 영업일 기준 1~2일 소요됩니다. 요청 결과는 요청 결과 조회하기 페이지를 통해 확인해 주세요."
      );
      setErrMsg({
        username: "",
        password: "",
        password2: "",
        advertisers: "",
        marketer: "",
        marketerPart: "",
        propertyId: "",
      });
      window.location.replace("/");
    } catch (error) {
      setErrMsg((prevState) => ({
        ...prevState,
        username: error.response.data.username || "",
        password: error.response.data.password || "",
        propertyId: error.response.data.propertyId || "",
      }));

      console.error("Error signing up:", error);
      if (error.response && error.response.data) {
        if (error.response.data.password) {
          alert(error.response.data.password);
          setErrMsg({});
        }
        if (error.response.data.username) {
          alert(error.response.data.username);
        }
        if (error.response.data.propertyId) {
          alert(error.response.data.propertyId);
        }
      } else {
        console.error("An error occurred:", error);
        alert("회원가입이 실패했습니다.");
      }
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="main-box">
      <Navbar />
      <h1 className="title-text">광고주 등록하기</h1>
      <div className="sign-box">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(CustomhandleSubmit)}
            className="sign-form-box"
          >
            <div className="sign-box-contents">
              <div className="sign-box-1">
                <div className="sign-input-text">
                  <label>아이디:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="text"
                      className="sign-input-text-input"
                      placeholder="영문, 숫자를 포함 6자 이상"
                      {...register("username", {
                        required: "아이디를 입력해주세요",
                        pattern: {
                          value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                          message: "영문, 숫자를 포함 6자 이상 입력해 주세요.",
                        },
                      })}
                      onChange={handleChange}
                    />
                    {errors.username && (
                      <p style={{ color: "red" }}>{errors.username.message}</p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>비밀번호:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="password"
                      className="sign-input-text-input"
                      placeholder="영문, 숫자, 특수기호를 포함 8자 이상"
                      {...register("password", {
                        required: "비밀번호를 입력해 주세요",
                        pattern: {
                          value:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                            "영문, 숫자, 특수기호를 포함 8자 이상 입력해 주세요.",
                        },
                      })}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p style={{ color: "red" }}>{errors.password.message}</p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>비밀번호 확인:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="password"
                      className="sign-input-text-input"
                      {...register("password2", {
                        required: "비밀번호를 확인해주세요",
                        validate: (value) =>
                          value === password || "비밀번호가 일치하지 않습니다.",
                      })}
                      onChange={handleChange}
                    />
                    {errors.password2 && (
                      <p style={{ color: "red" }}>{errors.password2.message}</p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>광고주명:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="text"
                      className="sign-input-text-input"
                      {...register("advertisers", {
                        required: "광고주명을 입력해 주세요",
                      })}
                      onChange={handleChange}
                    />
                    {errors.advertisers && (
                      <p style={{ color: "red" }}>
                        {errors.advertisers.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>담당마케터 이름:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="text"
                      className="sign-input-text-input"
                      {...register("marketer", {
                        required: "담당마케터 이름을 입력해 주세요",
                      })}
                      onChange={handleChange}
                    />
                    {errors.marketer && (
                      <p style={{ color: "red" }}>{errors.marketer.message}</p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>부문:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="text"
                      className="sign-input-text-input"
                      {...register("marketerPart", {
                        required: "부문을 입력해 주세요",
                      })}
                      onChange={handleChange}
                    />
                    {errors.marketerPart && (
                      <p style={{ color: "red" }}>
                        {errors.marketerPart.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sign-input-text">
                  <label>GA4 속성ID:</label>
                  <div className="sign-input-text-input-box">
                    <input
                      type="text"
                      className="sign-input-text-input"
                      placeholder="숫자만 입력해 주세요"
                      {...register("propertyId", {
                        required: "GA 속성ID를 입력해 주세요",
                        pattern: {
                          value: /^\d+$/,
                          message: "숫자만 입력해 주세요.",
                        },
                      })}
                      onChange={handleChange}
                    />
                    {errors.propertyId && (
                      <p style={{ color: "red" }}>
                        {errors.propertyId.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="sign-box-2">
                <MediaAdd mediaError={mediaError} errors={errors} />
              </div>
            </div>
            <div className="signUp-button-box">
              <button
                type="button"
                className="signUp-button-1"
                onClick={() => window.location.replace("/")}
              >
                뒤로가기
              </button>
              <button type="submit" className="signUp-button-2">
                제출하기
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default Signup;
import React, { useEffect, useState } from "react";
import styles from "./pwfindbox.module.scss";

export const CertBox = ({ isCertBoxVisible, code, handleSendCode, onAuthenticationSuccess  }) => {
    const [countdown, setCountdown] = useState(900);
    const [certCode, setCertCode] = useState("");
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // 인증 상태 추가

    // 인증 코드 유효성 체크
    useEffect(() => {
        setIsCodeValid(certCode.length === 6 && /^\d{6}$/.test(certCode));
    }, [certCode]);

    // 타이머 설정
    useEffect(() => {
        let timer;
        if (isCertBoxVisible && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCount => prevCount - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [isCertBoxVisible, countdown]);

    // 시간 포맷팅 함수
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    // 인증 버튼 클릭 핸들러
    const handleCertClick = () => {
        if (certCode === code) {
            setIsAuthenticated(true);  // 인증 상태를 true로 변경
            onAuthenticationSuccess(); 
        } else {
            setIsAuthenticated(false);
            alert("인증번호가 일치하지 않습니다. 다시 시도해주세요.");
        }
    };

    

    return (
        <div className={styles.certBox}>
            <p className={styles.certTxt1}>인증번호를 입력해주세요.</p>
            <p className={styles.certTxt2}>이메일을 확인하여 인증번호를 입력해주세요.</p>
            <div className={styles.certInput}>
                <input
                    placeholder="인증번호 입력"
                    value={certCode}
                    onChange={(e) => setCertCode(e.target.value)}
                />
                <button
                    className={isCodeValid ? styles.sendBtn : styles.certBtn}
                    onClick={handleCertClick}
                >
                    인증하기
                </button>
            </div>
            {isAuthenticated ? (
                <div className={styles.reCertBox}>
                    <p className={styles.reCertTxt4}>인증되었습니다.</p>
                </div>
            ):(
                <div className={styles.reCertBox}>
                    <p className={styles.reCertTxt1}>인증번호의 유효시간은 15분입니다.</p>
                    <p className={styles.reCertTxt2}>{formatTime(countdown)}</p>
                    <a className={styles.reCertTxt3} onClick={handleSendCode}>인증번호 재발송</a>
                </div>
            )}
            <div className={styles.certInfo}>
                <p className={styles.certInfoTxt1}>만약 인증 메일이 오지 않는다면?</p>
                <p className={styles.certInfoTxt2}>스팸 메일함을 확인해보세요.</p>
                <p className={styles.certInfoTxt2}>메일링 서비스에 따라 스팸메일로 분류될 수 있습니다.</p>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { setCode } from "../../../../../store/SocialSlice";
import { KakaoKeywordLinkApi, KakaoKeywordTargetApi } from '../../../../../api/socialLogin/KakaoApi';

const KakaoCallback = () => {
    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        const qsError = searchParams.get("error");
        console.log(code)
        console.log(qsError);
        
        dispatch(setCode(code))

        const getToken = async (code) => {
            try {
                const response = await KakaoKeywordLinkApi(code);
                if (response && response.media_link_id) {
                    const getTargetListResponse = await KakaoKeywordTargetApi(response.media_link_id)
                    console.log(getTargetListResponse);
                    if (getTargetListResponse.status==200){
                        setData(getTargetListResponse.data);
                        localStorage.setItem("kakao-keyword", JSON.stringify(getTargetListResponse.data));
                        navigate("/datalink/kakao/modal");
                    }
                }
                
            } catch (err) {
                console.log(err);
                navigate("/datalink")
            }
        };
        const MovePage = () =>{
            console.log(qsError);
            navigate("/datalink")
        }
        if (code) {
            getToken(code);
        }
        if (qsError) {
            console.log(qsError);
            MovePage(qsError)
        }
    }, [location.search, navigate]);


    return(
        <>
            {data ? <div>Data Loaded</div> : <div>Loading...</div>}
        </>
        
    )
};

export default KakaoCallback;

import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Adforest } from "../../../../assets/images/home/home-adforest.svg"

export const Rfm = () => {
    return (
        <div className={styles.rfmContent}>
            <div className={styles.predictBox}>
                <div className={styles.predictImg}>
                    <Adforest />
                </div>
                <p className={styles.predictTxt1}>RFM 분석 보고서</p>
                <p className={styles.predictTxt2}>RFM기법으로 데이터를 분석해보세요!</p>
                <p className={styles.predictTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}
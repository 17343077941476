import axios from "../../axios";

export const CostScoreCardApi = async ( media, startDate, endDate, accountId ) => {
    const path = `${media}/${accountId}/costs/step-process/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("비용 스코어카드 오류 발생:", error);
        throw error;
    }
};
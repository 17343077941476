import { useState } from "react";
import "./help.scss";
const Help = () => {
  return (
    <div className="help-detail-container">
      <div className="help-content item">
        애드포레스트를 이용해 주셔서 감사합니다.
      </div>
      <div className="help-title item">담당 마케터에게 문의해 주세요.</div>
    </div>
  );
};

export default Help;

import React from "react";

import styles from "./adforesthome.module.scss"

import { Banner } from "../../home/banner/Banner";
import { PreView } from "../../home/preview/PreView";
import { Menu } from "../../home/menu/Menu";

export const AdforestHome = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <Banner />
                <PreView />
            </div>
            <Menu />
        </div>
    );
};
import React, { useState, useEffect } from 'react';

import styles from '../scss/costtrend.module.scss';

import { CostTrendApi } from '../../../../api/report/cost/CostTrendApi';

import { BarLine } from '../../../utils/chart/BarLine';
import Loading from '../../../utils/loading/Loading';

export const CostTrend = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("day");

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostTrendApi(mediaName(), startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, selectedValue])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <div className={styles.costTrend}>
            <div className={styles.costTrendTitle}>광고비 추세</div>
            <div className={styles[`costTrendSelect${mediaInfoId}`]}>
                <select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="month">월별</option>
                    <option value="year">년별</option>
                </select>
            </div>
            <div className={styles.costTrendChart}>
                <BarLine data={data} lineLabel="전환 매출액" barLabel="광고비" mediaInfoId={mediaInfoId} />
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./socialsignup.module.scss";
import axios from "../../api/axios";
import { path } from "../../store/PathSlice";
import Sectors from "./inputBox/Sectors";
import Agreements from "./inputBox/Agreements";

import { ReactComponent as SingupLogo } from "../../assets/images/signup/signup-logo.svg";
import { ReactComponent as AdfLogo1 } from "../../assets/images/signup/adf-info-1.svg";
import { ReactComponent as AdfLogo2 } from "../../assets/images/signup/adf-info-2.svg";
import { ReactComponent as AdfLogo3 } from "../../assets/images/signup/adf-info-3.svg";

function SocialSignup() {
    const dispatch = useDispatch();
    const location = useLocation();
    const username = location.state?.username;
    const media = location.state?.media;
    const navigate = useNavigate();
    const url = location.pathname.split("/").pop();
    dispatch(path(url));
    console.log(media)

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    const [agreements, setAgreements] = useState({
        termsAgree: false,
        privacyAgree: false,
        adsAgree: false,
    });

    const methods = useForm({
        defaultValues: {
            adv_name: "",
        },
    });

    const [errMsg, setErrMsg] = useState({
        adv_name: "",
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = methods;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value); // react-hook-form에 값 설정
    };

    const CustomhandleSubmit = async (data) => {
        console.log(data)
        if (!agreements.termsAgree || !agreements.privacyAgree) {
            alert("이용약관 및 개인정보 처리방침에 동의하셔야 회원가입이 가능합니다.");
            return;
        }
        try {
            const response = await axios.post("common/register-add/", {
                ...data,
                username: username,
                media: media,
                selectedCategory: selectedCategory.value,
                selectedSubcategory: selectedSubcategory.value,
                termsAgree: agreements.termsAgree,
                privacyAgree: agreements.privacyAgree,
                adsAgree: agreements.adsAgree,
            });

            setErrMsg({
                username: "",
                adv_name: "",
            });
            
            navigate("/");
        } catch (error) {
            setErrMsg((prevState) => ({
                ...prevState,
                username: error.response.data.username || "",
            }));

            console.error("Error signing up:", error);

            if (error.response && error.response.data) {
                if (error.response.data.username) {
                    alert(error.response.data.username,"dsada");
                }
            } else {
                alert("회원가입이 실패했습니다.");
            }
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {});
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className={styles.mainBox}>
            <div className={styles.leftBox}>
                <div className={styles.adfInfo1}>
                    <div>
                        <SingupLogo />
                        <h3>에서</h3>
                    </div>
                    <h3>다양한 종류의 데이터를 쉽고 정확하게 분석해 보세요!</h3>
                </div>
                <div className={styles.adfInfo2}>
                    <AdfLogo1 />
                    <div>
                        <h3>다양한 매체의 데이터를 한 번에 확인할 수 있어요.</h3>
                        <h5>
                            여러 매체의 데이터를 통합적으로 분석하여 광고 성과를 더욱
                            정확하게 파악할 수 있습니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo3}>
                    <AdfLogo2 />
                    <div>
                        <h3>수많은 데이터를 기반으로 한 예측 모델을 경험해 보세요.</h3>
                        <h5>
                            광고성과를 예측하여 최적의 광고 전략을 수립할 수 있도록
                            도와줍니다.
                        </h5>
                    </div>
                </div>
                <div className={styles.adfInfo4}>
                    <AdfLogo3 />
                    <div>
                        <h3>시각적 자료를 활용해 더욱 직관적인 데이터를 제공합니다.</h3>
                        <h5>
                            데이터를 한눈에 파악할 수 있어, 광고 전략을 더욱 쉽게 수립할 수
                            있습니다.
                        </h5>
                    </div>
                </div>
            </div>
            <div className={styles.signBox}>
                <div className={styles.txtBox}>
                    <p className={styles.titleText}>추가정보 입력하기</p>
                    <p className={styles.txt1}>간편 회원가입을 완료해 주셔서 감사합니다.</p>
                    <p className={styles.txt2}>추가 정보를 입력하시고 필수 약관에 동의해 주시면<br/>
                        애드포레스트 서비스를 정상적으로 이용하실 수 있습니다.</p>
                    <p className={styles.txt3}>서비스 이용에 필요한 정보를 입력하시고, 약관을 꼼꼼히 읽어보신 후 동의해 주세요.</p>
                </div>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(CustomhandleSubmit)}
                        className={styles.signFormBox}
                    >
                        <div className={styles.advBox}>
                            <div className={styles.advInput}>
                                <input
                                    type="text"
                                    placeholder="광고주명을 입력해주세요."
                                    {...register("adv_name", {
                                        required: "광고주명을 입력해 주세요",
                                    })}
                                    onChange={handleChange}
                                />
                                {errors.adv_name && (
                                    <p style={{ color: "red" }}>
                                        {errors.adv_name.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <Sectors
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            selectedSubcategory={selectedSubcategory}
                            setSelectedSubcategory={setSelectedSubcategory}
                        />
                    </form>
                </FormProvider>
                <Agreements
                    checked={agreements}
                    onChange={setAgreements}
                />
                <div className={styles.signUpBox}>
                    <button onClick={handleSubmit(CustomhandleSubmit)}>
                        회원가입
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SocialSignup;

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { UnlinkDataApi } from "../../../../api/dataCenter/UnLinkDataApi";
import { ChangeNameApi } from "../../../../api/dataCenter/ChangeNameApi";
import { AdAccountsApi } from "../../../../api/dataCenter/AdAccountsApi";

import { ReactComponent as DataLinkGa4 } from "../../../../assets/images/datalink/datalink-ga4.svg";
import { ReactComponent as DataLinkGoogle } from "../../../../assets/images/datalink/datalink-google.svg";
import { ReactComponent as DataLinkNaver } from "../../../../assets/images/datalink/datalink-naver.svg";
import { ReactComponent as DataLinkKakao } from "../../../../assets/images/datalink/datalink-kakaologo.svg";
import { ReactComponent as DataLinkFacebook } from "../../../../assets/images/datalink/datalink-facebook.svg";
import { ReactComponent as DataLinkOption } from "../../../../assets/images/datalink/datalink-option.svg";

import styles from "../scss/box/linkdatalistbox.module.scss";
import Loading from "../../../utils/loading/Loading";
import { UnLinkBox } from "./UnLinkBox";
import { ChangeNameBox } from "./ChangeNameBox";
import { DataLinkCalendar } from "../modal/DataLinkCalendar";
import { UnLinkSuccessModal } from "../modal/UnLinkSuccessModal";



const LinkDataListBox = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedIncrementId, setSelectedIncrementId] = useState(null);
    const [selectedLinkDataName, setSelectedLinkDataName] = useState("");
    const [unLinkModal, setUnLinkModal] = useState(false);
    const [unLinkSuccessModal, setUnLinkSuccessModal] = useState(false);
    const dropdownRefs = useRef({});
    const username = useSelector((state) => state.user.username);

    useEffect(() => {
        const fetchData = async () => {
            const response = await AdAccountsApi(username);
            setData(response);
            setLoading(false);
        };

        fetchData();
    }, [username]);

    const handleUpdateName = async (incrementId, newName) => {
        try {
            await ChangeNameApi(incrementId, newName);
            setData((prevData) => ({
                ...prevData,
                data: prevData.data.map((item) =>
                    item.increment_id === incrementId ? { ...item, link_data_nm: newName } : item
                ),
            }));
        } catch (error) {
            console.error("이름 변경 중 오류 발생:", error);
        }
    };

    const handleUnlink = async (incrementId) => {
        try {
            await UnlinkDataApi(incrementId);
            setData((prevData) => ({
                ...prevData,
                data: prevData.data.filter((item) => item.increment_id !== incrementId),
            }));
            setUnLinkSuccessModal(true);
        } catch (error) {
            console.error("연결 해지 중 오류 발생:", error);
        }
    };

    const unLinkOpenModal = (incrementId, linkDataName) => {
        setSelectedIncrementId(incrementId);
        setSelectedLinkDataName(linkDataName);
        setUnLinkModal(true);
    };

    const unLinkCloseModal = () => {
        setUnLinkModal(false);
        setSelectedIncrementId(null);
    };

    const confirmUnlink = (incrementId) => {
        handleUnlink(incrementId);
        unLinkCloseModal();
    };

    const closeUnLinkSuccessModal = () => {
        setUnLinkSuccessModal(false);
    };

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id); // 드롭다운 열기/닫기
    };

    const handleCalendar = () => {
        setOpenCalendar(true)
    };

    const handleCloseCalendar = () => {
        setOpenCalendar(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            // 각 드롭다운의 ref가 있는지 확인하고, 클릭한 요소가 드롭다운 바깥인지 확인
            if (openDropdownId && dropdownRefs.current[openDropdownId] && !dropdownRefs.current[openDropdownId].contains(event.target)) {
                setOpenDropdownId(null); // 외부 클릭 시 드롭다운 닫기
            }
        };

        // 전역적으로 클릭 이벤트 등록
        document.addEventListener("mousedown", handleClickOutside);

        // 컴포넌트가 언마운트되면 이벤트 리스너를 제거
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdownId]);

    if (loading) {
        return <Loading />;
    }

    const renderImage = (media_info_code) => {
        switch (media_info_code) {
            case 5:
                return <DataLinkNaver />;
            case 7:
                return <DataLinkKakao />;
            case 10:
                return <DataLinkFacebook />;
            case 89:
                return <DataLinkKakao />;
            case 100:
                return <DataLinkGoogle />;
            case 101:
                return <DataLinkGa4 />;
            default:
                return <></>;
        }
    };

    const renderMediaName = (media_info_code) => {
        switch (media_info_code) {
            case 5:
                return "네이버 검색광고";
            case 7:
                return "카카오모먼트";
            case 10:
                return "페이스북";
            case 89:
                return "카카오키워드";
            case 100:
                return "구글 광고";
            case 101:
                return "구글 애널리틱스 4";
            default:
                return "";
        }
    };

    return (
        <div className={styles.linkDataList}>
            <h3 className={styles.linkDataListText}>연결된 데이터 리스트</h3>
            <div className={styles.linkDataListBox}>
                {data && data.data && data.data.length > 0 ? (
                    data.data.map((item) => (
                        <div key={item.increment_id} className={styles.listContent}>
                            <div className={styles.contentImg}>{renderImage(item.media_info_code)}</div>
                            <p className={styles.reportName}>{item.link_data_nm}</p>
                            <p className={styles.collectTime}>2시간 전 업데이트</p>
                            <div className={styles.mediaName}>{renderMediaName(item.media_info_code)}</div>
                            <div className={styles.contentTxt}>{item.account_nm}</div>
                            <div className={styles.collectBtnBox}>
                                {/* <button onClick={handleCalendar} className={styles.collectBtn}>데이터 수집</button>
                                {openCalendar && <DataLinkCalendar handleCloseModal={handleCloseCalendar} />} */}
                                <button onClick={() => unLinkOpenModal(item.increment_id, item.link_data_nm)} className={styles.collectBtn}>연결 해지</button>
                                {unLinkModal && 
                                    <UnLinkBox
                                        increment_id={selectedIncrementId}
                                        link_data_nm={selectedLinkDataName}
                                        handleUnlink={handleUnlink}
                                        onClose={unLinkCloseModal}
                                        onConfirm={confirmUnlink}
                                        unLinkSuccessModal={unLinkSuccessModal}
                                        closeUnLinkSuccessModal={closeUnLinkSuccessModal}
                                    />
                                }
                                {unLinkSuccessModal && (
                                    <UnLinkSuccessModal onClose={closeUnLinkSuccessModal} />
                                )}
                            </div>
                            <div className={styles.contentOption}>
                                <ChangeNameBox
                                    link_data_nm={item.link_data_nm}
                                    increment_id={item.increment_id}
                                    handleUpdateName={handleUpdateName}
                                />
                            </div>
                                {/* <div
                                    className={styles.contentOption}
                                ref={(el) => (dropdownRefs.current[item.increment_id] = el)} // 각 드롭다운에 대한 ref 설정
                            >
                                <button
                                    onClick={() => toggleDropdown(item.increment_id)}
                                    className={styles.contentOptionBtn}
                                >
                                    <DataLinkOption />
                                </button>
                                {openDropdownId === item.increment_id && (
                                    <div className={styles.dropdownBox}>
                                        <ChangeNameBox
                                            link_data_nm={item.link_data_nm}
                                            increment_id={item.increment_id}
                                            handleUpdateName={handleUpdateName}
                                        />
                                        <div className={styles.border}></div>
                                        <UnLinkBox
                                            increment_id={item.increment_id}
                                            link_data_nm={item.link_data_nm}
                                            handleUnlink={handleUnlink}
                                        />
                                    </div>
                                )}
                            </div> */}
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default LinkDataListBox;

import React from "react";

import styles from "./menu.module.scss"
import { ReactComponent as RightBtn } from "../../../assets/images/home/home-preview-rightBtn.svg"

import { MyData } from "./mydata/MyData";

export const Menu = () => {
    return (
        <div className={styles.main}>
            <div className={styles.linkToBox}>
                <p className={styles.linkToBoxTxt}>바로가기</p>
                <div className={styles.linkToContent}>
                    <p className={styles.contentTxt}>FAQ</p>
                    <RightBtn />
                </div>
                <div className={styles.linkToContent}>
                    <p className={styles.contentTxt}>공지사항</p>
                    <RightBtn />
                </div>
            </div>
            <MyData />
        </div>
    );
};
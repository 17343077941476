import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Ga4 } from "../../../../assets/images/home/home-ga4.svg"

export const GoogleA4 = () => {
    return (
        <div className={styles.ga4Content}>
            <div className={styles.ga4Box}>
                <div className={styles.ga4Img}>
                    <Ga4 />
                </div>
                <p className={styles.ga4Txt1}>GA4 광고 성과 분석 보고서</p>
                <p className={styles.ga4Txt2}>
                    GA4 광고 성과를 확인해보세요!
                </p>
                <p className={styles.ga4Txt3}>미리보기 →</p>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import "./banner.scss";
import { ReactComponent as CompanyIcon } from "../../../assets/images/share/company-icon.svg"; // 회사 아이콘을 위한 SVG 임포트
import { ReactComponent as InfoIcon } from "../../../assets/images/share/info-icon.svg"; // 매체 정보 아이콘을 위한 SVG 임포트
import { ReactComponent as GAIcon } from "../../../assets/images/share/ga-icon.svg"; // GA4 정보 아이콘을 위한 SVG 임포트
import { ReactComponent as PhonIcon } from "../../../assets/images/share/phon-icon.svg";
import { MyInfo } from "../../../api/mypage/MyInfoAPI";
const Banner = ({ selectTarget, setSelectTarget }) => {
  const bannerClicked = (target) => {
    setSelectTarget(target);
  };
  const [myInfo, setMyInfo] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MyInfo();
        setMyInfo(response);
        console.log(response);
      } catch (error) {
        console.error("Error : ", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="banner">
      <div className="banner-left">
        <div className="left-wrapper">
          <div className="left-circle">
            <div className="company-icon">
              <CompanyIcon />
            </div>
          </div>
        </div>

        <div className="company-info">
          <p>
            광고주명:{" "}
            {myInfo && myInfo.length > 0 && myInfo[0].username
              ? myInfo[0].username
              : "정보 없음"}
          </p>
          <p>
            담당 마케터 이름:{" "}
            {myInfo && myInfo.length > 0 && myInfo[0].marketer
              ? myInfo[0].marketer
              : "정보 없음"}
          </p>
        </div>
      </div>
      <div className="banner-right">
        <div className="banner-item">
          <div className="wrapper">
            <div
              className={selectTarget === "info" ? "circle click" : "circle"}
              onClick={() => bannerClicked("info")}
            >
              <div className="info-icon">
                <InfoIcon />
              </div>
            </div>
            <p>매체 정보</p>
          </div>
        </div>
        <div className="banner-item">
          <div className="wrapper">
            <div
              className={selectTarget === "ga" ? "circle click" : "circle"}
              onClick={() => bannerClicked("ga")}
            >
              <div className="ga-icon">
                <GAIcon />
              </div>
            </div>
            <p>GA4 정보</p>
          </div>
        </div>
        <div className="banner-item">
          <div className="wrapper">
            <div
              className={selectTarget === "help" ? "circle click" : "circle"}
              onClick={() => bannerClicked("help")}
            >
              <div className="phon-icon">
                <PhonIcon />
              </div>
            </div>
            <p>문의하기</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

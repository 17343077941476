import { useEffect, useState } from "react";
import DataTable from "../../../components/utils/table/Table";
import "./mediainfo.scss";
import { GetMediaApi } from "../../../api/mypage/GetMediaAPI";
import CustomPagination from "../../../components/utils/pagination/Pagination";
const MediaInfo = () => {
  const [page, setPage] = useState(1); // 현재 페이지
  const [mediaData, setMediaData] = useState([]);
  const sort = true
  const selectViews = 10;
  const headers = [
    {
      text: "번호",
      value: "index",
    },
    {
      text: "매체",
      value: "mediaName",
    },
    {
      text: "매칭ID",
      value: "matchingId",
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetMediaApi();
        setMediaData(response);
      } catch (error) {
        console.error("Error : ", error);
      }
    };
    fetchData();
  }, []);
  
  return (
    <div className="media-detail-info-container">
      <div className="media-info-title"> 나의 매체 정보</div>
      <div>
        {mediaData.length > 0 && (
          <div className="media-table">
            <DataTable
              headers={headers}
              totalItems={mediaData}
              sort={sort}
              selectViews={selectViews}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaInfo;

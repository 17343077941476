import React, { useEffect, useState } from "react";
import Select from "react-select";

import styles from "./datalinkbox.module.scss";

export const SelectBox = ({data, onOptionsChange, onShowInputChange}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]); // options를 상태로 관리

    useEffect(() => {
        let newOptions = [];

        if (data.fb_ids) {
            const fbOptions = data.fb_name.map((name, index) => ({
                value: data.fb_ids[index],
                label: name,
            }));
            newOptions = [...newOptions, ...fbOptions];
        }

        if (data.google_ids) {
            const googleOptions = data.google_name.map((name, index) => ({
                value: data.google_ids[index],
                label: name,
            }));
            newOptions = [...newOptions, ...googleOptions];
        }
        if (data.kakao_ids) {
            const kakaoOptions = data.kakao_names.map((name, index) => ({
                value: data.kakao_ids[index],
                label: name,
            }));
            newOptions = [...newOptions, ...kakaoOptions];
        }

        setOptions(newOptions); // options 상태 업데이트
    }, [data]); // data가 변경될 때마다 실행
    

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // height를 auto로 설정
            minHeight: '33px', // 최소 높이를 지정하여 초기 높이 설정
            maxHeight: '110px',
            overflowY: 'auto', // 스크롤 추가
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            color: "#000",
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            borderColor: state.isFocused ? "#000" : "#000",
            '&:hover': {
                borderColor: state.isFocused ? "#000" : "#000",
            },
            '::-webkit-scrollbar': {
                display: 'none'
            },
        }),
        menu: (provided) => ({
            ...provided,
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            color: "#000",
            borderRadius: "5px"
        }),
        option: (provided, state) => ({
            ...provided,
            width: '95%',
            marginLeft: '3px',
            marginRight: '3px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 600,
            backgroundColor: state.isSelected ? 'rgba(0, 160, 160, 0.2)' : 'white',
            color: state.isSelected ? '#00A0A0' : '#000',
            '&:hover': {
                backgroundColor: 'rgba(0, 160, 160, 0.2)',
                color: '#00A0A0'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menuList: (provided) => ({
            ...provided,
            paddingRight: '5px',
            '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
                marginRight: '5px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#D9D9D9',
                borderRadius: '5px',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#8C8C8C',
            },
            '::-webkit-scrollbar-track': {
                background: '#FFF',
                paddingRight: '5px'
            },
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#000' : '#000', // 화살표 색상 변경
            '&:hover': {
                color: '#000' // 화살표에 호버 시 색상 변경
            },
        }),
        indicatorSeparator: () => ({
            display: 'none', // 화살표와 텍스트 사이의 구분선 제거
        }),
        clearIndicator: (provided) => ({
            ...provided,
            display: 'none', // 전체 드롭다운을 닫는 'X' 버튼 숨기기
        }),
        // multiValue: (provided) => ({
        //     ...provided,
        //     borderRadius: '5px',
        //     backgroundColor: '#E0E0E0',
        //     color: '#333',
        // }),
        // multiValueLabel: (provided) => ({
        //     ...provided,
        //     color: '#333',
        // }),
        // multiValueRemove: (provided) => ({
        //     ...provided,
        //     display: 'none', // 'X' 버튼 숨기기
        // }),
    };

    const handleSelect = (selected) => {
        setSelectedOptions(selected || []);
        onOptionsChange(selected || []);
    };

    const handleSelectButtonClick = () => {
        onShowInputChange(true);
    };

    console.log(selectedOptions)

    return (
        <div className={styles.selectBox}>
            <p>광고 계정</p>
            <div className={styles.select}>
                <Select
                    isMulti
                    styles={customStyles}
                    options={options}
                    placeholder="광고 계정을 선택해주세요."
                    onChange={handleSelect} // 선택 변경 시 핸들러 호출
                />
            </div>
            {selectedOptions.length > 0 && (
                <div className={styles.selectBtn}>
                    <button onClick={handleSelectButtonClick}>선택</button>
                </div>
            )}
        </div>
    );
}
import React, { useState, useEffect } from 'react';

import styles from '../scss/reportscorecard.module.scss';

import { ClickScoreCardApi } from '../../../../api/report/click/ClickScoreCardApi';

import { ScoreCard } from '../../../utils/card/ScoreCard';
import Loading from '../../../utils/loading/Loading';

export const ReportScoreCard = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await ClickScoreCardApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = [
        {name: '노출수', value: `${data.data.tot_impression.toLocaleString()}회`, change: data.data.tot_impression_variance, operator: data.data.tot_impression_variance_operator},
        {name: '클릭수', value: `${data.data.tot_click.toLocaleString()}번`, change: data.data.tot_click_variance, operator: data.data.tot_click_variance_operator},
        {name: '클릭률', value: `${data.data.click_rate}%`, change: data.data.click_rate_variance, operator: data.data.click_rate_variance_operator},
        {name: '전환수', value: `${data.data.tot_conversion_count.toLocaleString()}회`, change: data.data.tot_conversion_count_variance, operator: data.data.tot_conversion_count_variance_operator},
        {name: '매출전환액', value: `${data.data.tot_conversion_amount.toLocaleString()}원`, change: data.data.tot_conversion_amount_variance, operator: data.data.tot_conversion_amount_variance_operator},
    ]

    return (
        <div className={styles.reportScoreCard}>
            <ScoreCard data={transformedData}/>
        </div>
    );
}
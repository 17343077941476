import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../page/home/Home";
import Login from "../page/login/Login";
import Signup from "../page/signup/Signup";
import Approve from "../page/approve/Approve";
import Mypage from "../page/mypage/Mypage";
import SignupCopy from "../page/signup/SignupCopy"
import { EmailVerify } from "../page/signup/EmailVerify";
import { Pwfind } from "../page/pwfind/Pwfind";
import { Pwchange } from "../page/pwchange/Pwchange";
import { FaceBookRedirect } from "../components/sidebar/dataLink/social/facebook/FaceBookRedirect";
import FacebookCallback from "../components/sidebar/dataLink/social/facebook/FacebookCallback";
import GoogleLoginCallback from "../components/login/google/GoogleCallback";
import SocialSignup from "../page/signup/SocialSingup";
import GoogleCallback from "../components/sidebar/dataLink/social/google/GoogleCallback";
import { GoogleRedirect } from "../components/sidebar/dataLink/social/google/GoogleRedirect";
import { Ga4Redirect } from "../components/sidebar/dataLink/social/ga4/Ga4Redirect";
import { NaverCallback } from "../components/login/naver/NaverCallback";
import { ReportCreatePage } from "../page/report/create/ReportCreatePage";
import { ReportFacebookPage } from "../page/report/facebook/ReportFacebookPage";
import { ReportGooglePage } from "../page/report/google/ReportGooglePage";
import { ReportKakaoPage } from "../page/report/kakao/ReportKakaoPage";
import { ReportNaverPage } from "../page/report/naver/ReportNaverPage";
import { Editor } from "../components/report/memo/Editor";
import KakaoCallback from "../components/sidebar/dataLink/social/kakao/KakaoCallback";
import { KakaoRedirect } from "../components/sidebar/dataLink/social/kakao/KakaoRedirect";
import KakaoMomentCallback from "../components/sidebar/dataLink/social/kakaomoment/KakaoMomentCallback";
import { KakaoMomentRedirect } from "../components/sidebar/dataLink/social/kakaomoment/KakaoMomentRedirect";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signupCopy" element={<SignupCopy />} />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path="/approve" element={<Approve />} />
        <Route path="/pw-find" element={<Pwfind />} />
        <Route path="/pw-change" element={<Pwchange />} />
        <Route path="/dashboard/*" element={<Home />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/datalink" element={<Home />} />
        <Route path="/datalink/facebook/*" element={<FacebookCallback />} />
        <Route path="/datalink/facebook/modal" element={<FaceBookRedirect />} />
        <Route path="/datalink/ga4/*" element={<Ga4Redirect />} />
        <Route path="/login/google/*" element={<GoogleLoginCallback />} />
        <Route path="/social/signup" element={<SocialSignup />} />
        <Route path="/datalink/google/*" element={<GoogleCallback />} />
        <Route path="/datalink/google/modal" element={<GoogleRedirect />} />
        <Route path="/datalink/kakao/*" element={<KakaoCallback />} />
        <Route path="/datalink/kakao/modal" element={<KakaoRedirect />} />
        <Route path="/datalink/moment/*" element={<KakaoMomentCallback />} />
        <Route path="/datalink/moment/modal" element={<KakaoMomentRedirect />} />
        <Route path="/login/naver/*" element={<NaverCallback />} />
        <Route path="report" element={<ReportCreatePage />} />
        <Route path="report/facebook/*" element={<ReportFacebookPage />} />
        <Route path="report/google/sa/*" element={<ReportGooglePage />} />
        <Route path="report/kakao/sa/*" element={<ReportKakaoPage />} />
        <Route path="report/naver/sa/*" element={<ReportNaverPage />} />
        <Route path="report/memo" element={<Editor />} />
      </Routes>
    </BrowserRouter>
  );
}

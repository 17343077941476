import { useEffect, useState } from "react";
import "./gainfo.scss";
import { GetGa4Api } from "../../../api/mypage/GetGa4API";
const GaInfo = () => {
  const [gaInfo, setGaInfo] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetGa4Api();
        setGaInfo(response)
        console.log(response);

      } catch (error) {
        console.error("Error : ", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="ga-detail-container">
      <div className="ga-title">나의 GA4 정보</div>
      <div className="ga-content">GA 속성 ID : {gaInfo}</div>
    </div>
  );
};

export default GaInfo;

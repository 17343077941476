import React from 'react';

import styles from './scorecard.module.scss';

export const ScoreCard = ({data}) => {
    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    return (
        <>
            {data.map((item, index) => (
                <div key={index} className={styles.scoreCard}>
                    <div className={styles.scoreCardTitle}>{item.name}</div>
                    <div className={styles.scoreCardValue}>{item.value}</div>
                    <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                        {item.operator === "up" ? `+${item.change}%` : item.operator === "down" ? `-${item.change}%` : "-"}
                    </div>
                </div>
            ))}
        </>
    );
} 
import React, { useState } from "react";

import styles from "./reportcreate.module.scss";
import { ReactComponent as Naver } from "../../../assets/images/home/home-report-naver.svg";
import { ReactComponent as Kakao } from "../../../assets/images/home/home-report-kakao.svg";
import { ReactComponent as Ga4 } from "../../../assets/images/home/home-report-ga4.svg";
import { ReactComponent as Google } from "../../../assets/images/home/home-report-google.svg";
import { ReactComponent as Facebook } from "../../../assets/images/home/home-report-facebook.svg";

import { ReportList } from "../list/ReportList";
import { ReportDetail } from "../detail/ReportDetail";

export const ReportCreate = () => {
    const [openDetails, setOpenDetails] = useState("default");
    const [isReportOpen, setIsReportOpen] = useState([false, false, false, false, false]);
    const [media, setMedia] = useState(null)

    const reportData = [
        {
            icons: [<Ga4 />, <Naver />, <Kakao />, <Facebook />, <Google />],
            title: "매체 통합 보고서",
            description: "매체 통합 네이버 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
            dataLink: "GA4 데이터를 연결해주세요.",
            dataLink2: "매체 데이터를 연결해주세요.",
            value: "total"
        },
        {
            icons: [<Naver />],
            title: "네이버 보고서",
            description: "네이버 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
            dataLink: "Naver 데이터를 연결해주세요.",
            value: "naver"
        },
        {
            icons: [<Kakao />],
            title: "카카오 보고서",
            description: "카카오 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
            dataLink: "Kakao 데이터를 연결해주세요.",
            value: "kakao"
        },
        {
            icons: [<Facebook />],
            title: "페이스북 보고서",
            description: "페이스북 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
            dataLink: "Facebook 데이터를 연결해주세요.",
            value: "facebook"
        },
        {
            icons: [<Google />],
            title: "구글 보고서",
            description: "구글 광고 성과 데이터 분석 대시보드에 대한 간략한 설명을 작성해줍니다.",
            dataLink: "Google 데이터를 연결해주세요.",
            value: "google"
        },
    ];

    const handleDetailClick = (value, index) => {
        setMedia(value)
        setOpenDetails((prevState) => (prevState === value ? "default" : value));
        setIsReportOpen((prevState) => {
            const newState = Array(reportData.length).fill(false); // 모든 보고서를 접기 상태로 초기화
            newState[index] = !prevState[index]; // 클릭된 보고서의 상태 토글
            return newState;
        });
    };

    return (
        <div className={styles.main}>
            <h2 className={styles.title}>보고서 선택하기</h2>
            <div className={styles.container}>
                <ReportList reportData={reportData} handleDetailClick={handleDetailClick} isReportOpen={isReportOpen} media={media} />
                <ReportDetail openDetails={openDetails} />
            </div>
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./sidebar.module.scss";
import { ReactComponent as DashboardMark } from "../../assets/images/dashboard.svg";
import { ReactComponent as DataLinkMark } from "../../assets/images/datalink/dataLink.svg";
import { ReactComponent as NotificationsMark } from "../../assets/images/share/notifications.svg";

import { setMenu, resetMenu } from "../../store/SelectedSlice";
import { ReportList } from "../../components/sidebar/home/list/ReportList";

function Sidebar({ defaultSelected }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split("/").pop();    
  const selected = useSelector((state) => state.selected.setMenu);
  const username = useSelector((state) => state.user.username);

  useEffect(() => {
    // URL이 바뀔 때 selected 값을 동기화
    if (url === 'datalink' && selected !== 'datalink') {
      dispatch(setMenu('datalink'));
    } else if (url === 'home' && selected !== 'home') {
      dispatch(setMenu('home'));
    }
  }, [url, selected, dispatch]);

  const handleMenuClick = (menu) => {
    // 메뉴를 클릭할 때 selected 값과 URL을 동기화
    if (selected === menu) {
      dispatch(resetMenu());
    } else {
      dispatch(setMenu(menu));
      navigate(`/${menu}`);
    }
  };

  const alertClick = () => {
    alert("현재 준비 중인 기능이에요!");
  };

  const handlePageAddClick = (url) => {
    dispatch(resetMenu());
    navigate(`/${url}`);
  };

  return (
    <div className={styles.mainSide}>
      <div className={styles.sideBar}>
        <div className={styles.sideButton}>
          <button
            onClick={() => handleMenuClick("home")}
            className={
              selected === "home" ? styles.sideActive : styles.sideButtonBtn
            }
          >
            <DashboardMark />
            <Link to="/home">애드포레스트 홈</Link>
          </button>
          <button
            onClick={() => handleMenuClick("datalink")}
            className={selected === "datalink" ? styles.sideActive : styles.sideButtonBtn}
          >
            <DataLinkMark />
            <Link to="/datalink"> 데이터 센터</Link>
          </button>
          <button
            onClick={alertClick}
            className={
              selected === "notifications" ? styles.sideActive : styles.sideButtonBtn
            }
          >
            <NotificationsMark />
            알림설정
          </button>
        </div>
        <div className={styles.sidePageAdd}>
          <div className={styles.reportBox}>
            <p className={styles.reportTitle}>생성된 보고서</p>
            <ReportList username={username} />
          </div>
          <button 
            onClick={() => handlePageAddClick("report")}
            className={styles.sidePageAddBtn}>
            <Link to="/report">새 보고서 추가하기</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

import React from "react";

import styles from "./pwfind.module.scss"
import {ReactComponent as Logo} from "../../assets/images/share/pwfind-logo.svg"

import { PwfindBox } from "../../components/pwfind/PwfindBox";

export const Pwfind = () => {
    return (
        <div className={styles.pwfMain}>
            <div className={styles.pwfHeader}>
                <Logo />
            </div>
            <div className={styles.pwfBody}>
                <PwfindBox />
            </div>
        </div>
    );
};


import axios from "../../axios";

export const ClickDayImpressionApi = async ( media, startDate, endDate, accountId ) => {
    const path = `${media}/${accountId}/clicks/dayweek/`
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate
            }
        });
        return data;
    } catch (error) {
        console.error("요일별 노출/클릭 오류 발생:", error);
        throw error;
    }
};